import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector} from "react-redux";
import LeftMenuList from "../Common/LeftMenuList";
import TopNavbar from "../Common/TopNavbar";
import "daterangepicker";
import moment from "moment";
import $ from "jquery";
import NewReports from "./Report/EnterpriseData";
import Highcharts from "highcharts";
import axios from "axios";
import { EnergyMeterApi } from "../Slices/EnergymeterSlice";
import Loader from "../utils/Loader";

const Energymeter = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userType, setUserType] = useState("meterData");
  const [settingsComplete, setSettingsComplete] = useState(false);
  const dateRangePickerRef = useRef(null);
  const [selectedEnterprise, setSelectedEnterprise] = useState("");
  const [selectedCountryState, setSelectedCountryState] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedGateway, setSelectedGateway] = useState("");
  const [selectedOptimizer, setSelectedOptimizer] = useState("");
  const [interval, setInterval] = useState(""); // Default interval is unset
  const [checkboxValues, setCheckboxValues] = useState({
    kvah: false,
    kwh: false,
    powerFactor: false,
  });
  const [formDisabled, setFormDisabled] = useState(false);
  const [errors, setErrors] = useState({
    country: false,
    state: false,
    city: false,
    optimizers: false,
    dates: false,
  });
  const [showChart, setShowChart] = useState(false);
  const [chartData, setChartData] = useState(null);

  const [gatewayData, setGatewayData] = useState(null);
  const [optimizerData, setOptimizerData] = useState(null);

  const { loading, response, error } = useSelector((state) => state.energyMeterSlice);

  const header = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxValues((prev) => ({ ...prev, [name]: checked }));
  };

  const fetchData = async () => {
    try {
      const data = {
        Gatewayid: selectedGateway,
        startDate,
        endDate,
        Interval: interval,
      };
  
      dispatch(EnergyMeterApi({ data, header })).then((response) => {
        const data = response.payload;
  
        if (data.success && data.response.DailyData.length > 0) {
          setChartData(data.response.DailyData);
          setErrors((prevErrors) => ({
            ...prevErrors,
            message: "",
          }));
        } else {
          setChartData(null); // Clear chart data
          setErrors((prevErrors) => ({
            ...prevErrors,
            message: "No data available for the selected parameters.",
          }));
          // Clear chart container when no data is available
          document.getElementById("container").innerHTML = "";

          setTimeout(() => {
            setChartData(null); // Ensure chart data is cleared
            setErrors((prevErrors) => ({
              ...prevErrors,
              message: "",
            }));
            document.getElementById("container").innerHTML = ""; // Clear chart container
          }, 5000); 
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        message: "An error occurred while fetching data.",
      }));
      setChartData(null); // Clear chart data
      // Clear chart container on error
      document.getElementById("container").innerHTML = "";
    }
  };
  
  const renderChart = (data) => {
    
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = moment(a.timePeriod, "DD-MM-YYYY", true); 
      const dateB = moment(b.timePeriod, "DD-MM-YYYY", true); 
  
      console.log(`a: ${a.timePeriod}, b: ${b.timePeriod}, dateA: ${dateA}, dateB: ${dateB}`); 
  
      
      if (!dateA.isValid()) return 1; 
      if (!dateB.isValid()) return -1; 
  
      return dateA.isBefore(dateB) ? -1 : 1; 
    });
  
    let categories = sortedData.map((item) => item.timePeriod);
  
    
    if (interval === "hourly") {
      categories = categories.map((item) => {
        const dateTime = moment(item, "DD-MM-YYYY", true); 
        return dateTime.isValid() ? dateTime.format("HH:mm") : item;
      });
    }
  
    // Extract kWh, kVAh, and Power Factor data from sortedData
    const kWhData = sortedData.map((item) => item.total_kWh);
    const kVAhData = sortedData.map((item) => item.total_kVAh);
    const pfData = sortedData.map((item) => item.avg_PF);
  
    // Prepare seriesData based on checkbox selections
    const seriesData = [];
    if (checkboxValues.kvah) {
      seriesData.push({
        name: "KVah",
        type: "column",
        color: "#0077FF",
        data: kVAhData,
      });
    }
    if (checkboxValues.kwh) {
      seriesData.push({
        name: "KWh",
        type: "column",
        color: "#33B25D",
        data: kWhData,
      });
    }
    if (checkboxValues.powerFactor) {
      seriesData.push({
        name: "Power Factor",
        type: "spline",
        color: "#FF9D00",
        data: pfData,
        yAxis: 1,
      });
    }
  
    // Prepare the chart title
    let chartTitle = `Energy Usage (${
      interval.charAt(0).toUpperCase() + interval.slice(1)
    })`;
    if (interval === "hourly") {
      const selectedDate = moment.utc(startDate, "M/DD/YYYY hh:mm:ss A").format(
        "MMMM D, YYYY"
      );
      chartTitle += ` - ${selectedDate}`;
    }
  
    // Render the chart with Highcharts
    Highcharts.chart("container", {
      chart: { zoomType: "xy" },
      title: { text: chartTitle },
      xAxis: {
        categories: categories,
        crosshair: true,
        labels: { rotation: 0 },
      },
      yAxis: [
        { title: { text: "Energy Usage (kVAh, kWh)" }, min: 0 },
        { title: { text: "Power Factor" }, opposite: true, min: 0, max: 1 },
      ],
      tooltip: { shared: true, useHTML: true },
      plotOptions: {
        column: { pointPadding: 0.2, borderWidth: 0 },
        line: { marker: { enabled: true } },
      },
      credits: {
        enabled: false,
      },
      series: seriesData,
    });
  };
  
  
  

  const handleApply = () => {
    let hasErrors = false;

    const newErrors = {
      dates: false,
      gateway: false,
      checkboxes: false,
      interval: false,
      message: "",
    };

    if (!selectedGateway) {
      newErrors.gateway = true;
      newErrors.message =
        "Please select all required fields before proceeding.";
      hasErrors = true;
    }

    if (selectedGateway) {
      if (!startDate || !endDate) {
        newErrors.dates = true;
        hasErrors = true;
      }

      if (
        !checkboxValues.kvah &&
        !checkboxValues.kwh &&
        !checkboxValues.powerFactor
      ) {
        newErrors.checkboxes = true;
        hasErrors = true;
      }

      if (!interval) {
        newErrors.interval = true;
        hasErrors = true;
      }
    }

    if (hasErrors) {
      setErrors(newErrors);
      setShowChart(false);
      return;
    }

    setErrors({
      dates: false,
      gateway: false,
      interval: false,
      checkboxes: false,
      message: "",
    });
    setShowChart(true);
    fetchData();
  };

  useEffect(() => {
    if (chartData) {
      renderChart(chartData);
    }
  }, [chartData]);

  useEffect(() => {
    setTimeout(() => {
      if (dateRangePickerRef.current) {
        const newStartDate = moment().startOf("day");
        const newEndDate = moment(newStartDate).endOf("day");
  
        $(dateRangePickerRef.current).daterangepicker({
          timePicker: true,
          startDate: newStartDate,
          endDate: newEndDate,
          maxDate: moment().endOf("day"), // Disable future dates
          locale: { format: "M/DD/YYYY hh:mm A" },
        });
  
        $(".daterangepicker .calendar-time").css({
          "pointer-events": "none",
          cursor: "not-allowed",
          opacity: "0.5",
        });
  
        $(dateRangePickerRef.current).on(
          "apply.daterangepicker",
          (ev, picker) => {
            const start = picker.startDate.format("M/DD/YYYY hh:mm:ss A");
            const end = picker.endDate.format("M/DD/YYYY hh:mm:ss A");
            setStartDate(start);
            setEndDate(end);
  
            setInterval("");
          }
        );
      }
    }, 100);
  
    return () => {
      if ($(dateRangePickerRef.current).data("daterangepicker")) {
        $(dateRangePickerRef.current).data("daterangepicker").remove();
      }
    };
  }, []);

  useEffect(() => {
    if (errors.message || errors.dates || errors.interval) {
      const timer = setTimeout(() => {
        setErrors({});
      }, 5000); // Clears errors after 5 seconds
      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }
  }, [errors]);

  return (
    <>
    {loading && <Loader />}
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <LeftMenuList />
      <div className="flex flex-col flex-1 w-full">
        <TopNavbar />
        <main className="h-full overflow-y-auto">
          <div className="container grid px-6 mx-auto">
            <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
              EnergyMeter
            </h2>
            <div className="w-full mb-8 overflow-hidden rounded-lg shadow-xs relative">
              {/* General error message */}
              {errors.message && (
                <span
                  style={{ color: "red" }}
                  className="text-sm mt-2 ml-2 font-bold"
                >
                  {errors.message}
                </span>
              )}

              {/* Individual field errors */}
              {errors.dates && selectedGateway && (
                <span style={{ color: "red" }} className="text-sm mt-2 ml-2">
                  Please select a valid date range.
                </span>
              )}

              {errors.interval && (
                <span
                  style={{ color: "red" }}
                  className="text-sm ml-2 mt-2 ml-2 "
                >
                  Please select an interval.
                </span>
              )}

              {errors.checkboxes && selectedGateway && (
                <span style={{ color: "red" }} className="text-sm mt-2 ml-2">
                  Please select at least one data type (KVah, KWh, or Power
                  Factor).
                </span>
              )}

              <form id="report_form">
                <NewReports
                  selectedEnterprise={selectedEnterprise}
                  selectedCountryState={selectedCountryState}
                  selectedLocation={selectedLocation}
                  selectedGateway={selectedGateway}
                  selectedOptimizer={selectedOptimizer}
                  setSelectedEnterprise={setSelectedEnterprise}
                  setSelectedLocation={setSelectedLocation}
                  setSelectedCountryState={setSelectedCountryState}
                  setSelectedGateway={setSelectedGateway}
                  setSelectedOptimizer={setSelectedOptimizer}
                  formDisabled={formDisabled}
                  hideOptimizer={true}
                  setGatewayData={setGatewayData}
                  setOptimizerData={setOptimizerData}
                />

                <div className="mb-2 flex space-x-3 px-6">
                  <div>
                    <label className="block text-sm">
                      <span className="text-gray-700 dark:text-gray-400">
                        Date <span style={{ color: "red" }}> *</span>
                      </span>
                      <input
                        ref={dateRangePickerRef}
                        name="datetimes"
                        className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                        placeholder="Select date range"
                        disabled={formDisabled}
                      />
                    </label>
                  </div>

                  <div>
                    <label className="block text-sm">
                      <span className="text-gray-700 dark:text-gray-400">
                        Interval
                      </span>
                      <select
                        className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                        value={interval}
                        onChange={(e) => setInterval(e.target.value)}
                        disabled={formDisabled || !startDate || !endDate}
                      >
                        <option value="" disabled>
                          Select Interval
                        </option>
                        {moment(endDate).diff(moment(startDate), "days") ===
                          0 && (
                          <>
                            <option value="hourly">Hourly</option>
                            <option value="daily">Daily</option>
                          </>
                        )}
                        {moment(endDate).diff(moment(startDate), "days") ===
                          1 && <option value="daily">Daily</option>}
                        {moment(endDate).diff(moment(startDate), "days") > 1 &&
                          moment(endDate).diff(moment(startDate), "days") <=
                            13 && <option value="daily">Daily</option>}
                        {moment(endDate).diff(moment(startDate), "days") >=
                          14 &&
                          moment(endDate).diff(moment(startDate), "days") <=
                            30 &&
                          // For the same month, show only daily and fortnight options
                          (moment(startDate).month() ===
                          moment(endDate).month() ? (
                            <>
                              <option value="daily">Daily</option>
                              <option value="fortnight">Fortnight</option>
                            </>
                          ) : (
                            // For different months, include monthly as well
                            <>
                              <option value="daily">Daily</option>
                              <option value="fortnight">Fortnight</option>
                              <option value="monthly">Monthly</option>
                            </>
                          ))}
                        {moment(endDate).diff(moment(startDate), "days") > 30 &&
                          moment(endDate).diff(moment(startDate), "days") <=
                            240 && (
                            <>
                              <option value="fortnight">Fortnight</option>
                              <option value="monthly">Monthly</option>
                            </>
                          )}
                      </select>
                    </label>
                  </div>
                  <div className="mb-2 flex space-x-3 px-6">
                    <div>
                      <label className="block text-sm">
                        <span className="text-gray-700 dark:text-gray-400">
                          Select Parameters
                        </span>
                        <div className="flex flex-wrap">
                          <label className="inline-flex items-center mt-2">
                            <input
                              type="checkbox"
                              className="form-checkbox h-5 w-5 text-purple-600"
                              name="kvah"
                              checked={checkboxValues.kvah}
                              onChange={handleCheckboxChange}
                              disabled={formDisabled}
                            />
                            <span className="ml-2 text-gray-700 dark:text-gray-400">
                              KVah
                            </span>
                          </label>
                          <label className="inline-flex items-center mt-2 ml-6">
                            <input
                              type="checkbox"
                              className="form-checkbox h-5 w-5 text-purple-600"
                              name="kwh"
                              checked={checkboxValues.kwh}
                              onChange={handleCheckboxChange}
                              disabled={formDisabled}
                            />
                            <span className="ml-2 text-gray-700 dark:text-gray-400">
                              KWh
                            </span>
                          </label>
                          <label className="inline-flex items-center mt-2 ml-6">
                            <input
                              type="checkbox"
                              className="form-checkbox h-5 w-5 text-purple-600"
                              name="powerFactor"
                              checked={checkboxValues.powerFactor}
                              onChange={handleCheckboxChange}
                              disabled={formDisabled}
                            />
                            <span className="ml-2 text-gray-700 dark:text-gray-400">
                              Power Factor
                            </span>
                          </label>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="px-6 py-3">
                    <button
                      type="button"
                      className="px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                      onClick={handleApply}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </form>
              {showChart && <div id="container" className="w-full mt-6"></div>}
            </div>
          </div>
        </main>
      </div>
    </div>
    </>
  );
};

export default Energymeter;
