import React, { useState, useEffect } from "react";
import logo from "./logo_.png";
import { Link } from "react-router-dom";
import IdleTimer from "../IdleTimer/IdleTimer";
import "../Pages/Enterprise/customStyles.css";

function LeftMenuList({ isSideMenuOpen }) {
  const [selectedItem, setSelectedItem] = useState({
    Dashboard: false,
    Report: false,
    Enterprise: false,
    User: false,
    Settings: false,
  }); // State to track the selected item
  const [sessionTimeout, setSessionTimeout] = useState([]);

  const handleItemClick = (itemName) => {
    setSelectedItem((prevSelectedItem) => {
      // Create a new object with all items set to false
      const newSelectedItem = Object.fromEntries(
        Object.keys(prevSelectedItem).map((key) => [key, false])
      );

      // Set the clicked item to true
      newSelectedItem[itemName] = true;

      return newSelectedItem;
    });
  };

  const renderSpanForItem = (itemName) => {
    if (selectedItem[itemName]) {
      return (
        <span
          className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
          aria-hidden="true"
        ></span>
      );
    }
    return null;
  };
  useEffect(() => {
    const data = process.env.REACT_APP_TIME;
    const session = <IdleTimer data={data} />;
    setSessionTimeout(session);
  }, []);

  return (
    <div
      className={`flex h-screen bg-gray-50 dark:bg-gray-900 ${
        isSideMenuOpen ? "overflow-hidden" : ""
      }`}
    >
      {sessionTimeout}
      <aside className="z-20 hidden w-64 overflow-y-auto bg-white dark:bg-gray-800 md:block flex-shrink-0">
        <div className="py-4 text-gray-500 dark:text-gray-400">
          <Link
            className=" text-lg font-bold text-gray-800 dark:text-gray-200"
            to="/dashboard"
          >
            <img src={logo} alt="" className="w-56" />
          </Link>
          <ul className="mt-6">
            <li
              className="relative px-6 py-3"
              onClick={() => handleItemClick("Dashboard")}
            >
              <Link
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 custom-link"
                to="/dashboard"
              >
                {renderSpanForItem("Dashboard")}
                <svg
                  width="24"
                  height="24"
                  fill="#000000"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fill-rule="evenodd"
                      d="M7,22 C5.34314575,22 4,20.6568542 4,19 L4,13 L3,13 C2.10909515,13 1.66292836,11.9228581 2.29289322,11.2928932 L11.2928932,2.29289322 C11.6834175,1.90236893 12.3165825,1.90236893 12.7071068,2.29289322 L21.7071068,11.2928932 C22.3370716,11.9228581 21.8909049,13 21,13 L20,13 L20,19 C20,20.6568542 18.6568542,22 17,22 L7,22 Z M12,4.41421356 L5.35091163,11.0633019 C5.73009945,11.2054205 6,11.5711909 6,12 L6,19 C6,19.5522847 6.44771525,20 7,20 L8.99999525,19.999 L9,16 C9,14.8954305 9.8954305,14 11,14 L13,14 C14.1045695,14 15,14.8954305 15,16 L14.9999952,19.999 L17,20 C17.5522847,20 18,19.5522847 18,19 L18,12 C18,11.5711909 18.2699006,11.2054205 18.6490884,11.0633019 L12,4.41421356 Z M12.9999952,16 L10.9999952,16 L10.9999952,19.999 L12.9999952,19.999 L12.9999952,16 Z"
                    ></path>{" "}
                  </g>
                </svg>
                <span className="ml-4">Dashboard</span>
              </Link>
            </li>
            <li
              className="relative px-6 py-3"
              onClick={() => handleItemClick("Report")}
            >
              <Link
                to="/report"
                onClick={() => handleItemClick("Report")}
                className={`inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 custom-link ${
                  selectedItem === "Report"
                    ? "text-gray-800 dark:text-gray-200"
                    : ""
                }`}
              >
                {renderSpanForItem("Report")}
                <svg
                  width="24"
                  height="24"
                  
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 10h16M4 14h16M4 18h16"></path>
                </svg>
                <span className="ml-4">Report</span>
              </Link>
            </li>

            <li
              className="relative px-6 py-3"
              onClick={() => handleItemClick("Enterprise")}
            >
              <Link
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 custom-link"
                to="/enterprise"
                onClick={() => handleItemClick("Enterprise")}
              >
                {renderSpanForItem("Enterprise")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <span className="ml-4">Enterprise</span>
              </Link>
            </li>
            <li
              className="relative px-6 py-3"
              onClick={() => handleItemClick("User")}
            >
              <Link
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 custom-link"
                to="/user"
              >
                {renderSpanForItem("User")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentcolor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>

                <span className="ml-4">User</span>
              </Link>
            </li>
            <li
              className="relative px-6 py-3"
              onClick={() => handleItemClick("ByPass")}
            >
              <Link
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 custom-link"
                to="/bypass"
              >
                {renderSpanForItem("ByPass")}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24.00 24.00"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  transform="matrix(1, 0, 0, 1, 0, 0)"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M10 3H14M12 9V13L14 15M20 13C20 17.4183 16.4183 21 12 21C7.58172 21 4 17.4183 4 13C4 8.58172 7.58172 5 12 5C16.4183 5 20 8.58172 20 13Z"
                      stroke="#000000"
                      stroke-width="1.6799999999999997"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>

                <span className="ml-4">By Pass</span>
              </Link>
            </li>

            <li
              className="relative px-6 py-3"
              onClick={() => handleItemClick("Deloitte Dataset")}
            >
              <Link
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 custom-link"
                to="/deloitte"
              >
                {renderSpanForItem("Deloitte Dataset")}
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 3v4a1 1 0 0 1-1 1H5m4 10v-2m3 2v-6m3 6v-3m4-11v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Z"
                  />
                </svg>

                <span className="ml-4">Deloitte Dataset</span>
              </Link>
            </li>

            <li
              className="relative px-6 py-3"
              onClick={() => handleItemClick("Energy Meter")}
            >
              <Link
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 custom-link"
                to="/energymeter"
              >
                {renderSpanForItem("Energy Meter")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="bi bi-lightning-charge"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41z" />
                </svg>

                <span className="ml-4">Energy Meter</span>
              </Link>
            </li>

            <li
              className="relative px-6 py-3"
              onClick={() => handleItemClick("Settings")}
            >
              <Link
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 custom-link"
                to="/settings"
              >
                {renderSpanForItem("Settings")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentcolor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="12" cy="12" r="3"></circle>
                  <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                </svg>
                <span className="ml-4">Settings</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
}

export default LeftMenuList;
