import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchEnterprises } from "../../Slices/Enterprise/NewEnterpriseDataSlice";

function NewReports({
  selectedEnterprise,
  selectedCountryState,
  selectedLocation,
  selectedGateway,
  selectedOptimizer,
  setSelectedEnterprise,
  setSelectedLocation,
  setSelectedCountryState,
  setSelectedGateway,
  setSelectedOptimizer,
  formDisabled,
  hideOptimizer,
  isOptimizerDisabled,
  selectedTab,
  setGatewayData,
  setOptimizerData
}) {
  const header = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
  };

  const dispatch = useDispatch();
  const { enterprises, loading, error } = useSelector(
    (state) => state.enterpriseDataSlice
  );

  // Fetch enterprises on component mount
  useEffect(() => {
    dispatch(fetchEnterprises({ header }));
  }, [dispatch]);

  // Set default enterprise as "State Bank of India" only for specific tabs
  useEffect(() => {
    if (
      enterprises.length > 0 &&
      (selectedTab === "availableReports" || selectedTab === "newReports")) {
      const defaultEnterprise = enterprises.find(
        // (enterprise) => enterprise.enterpriseName === "AaensaTesting"
        (enterprise) => enterprise.enterpriseName === "State Bank of India"
      );

      if (defaultEnterprise) {
        setSelectedEnterprise(defaultEnterprise.entepriseId);
      }
    }
  }, [enterprises, selectedTab, selectedEnterprise, setSelectedEnterprise]);

  // Handlers for field changes
  const handleEnterpriseChange = (event) => {
    setSelectedEnterprise(event.target.value);
    setSelectedCountryState("");
    setSelectedLocation("");
    setSelectedGateway("");
    setSelectedOptimizer("");
  };

  const handleCountryStateChange = (event) => {
    setSelectedCountryState(event.target.value);
    setSelectedLocation("");
    setSelectedGateway("");
    setSelectedOptimizer("");
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
    setSelectedGateway("");
    setSelectedOptimizer("");
  };

  const handleGatewayChange = (event) => {
    setSelectedGateway(event.target.value);
    setSelectedOptimizer("");

    const gatewayData = gateways.find((gateway) => gateway._id === event.target.value);
    if (gatewayData) {
      setGatewayData(gatewayData);
    }
  };

  const handleOptimizerChange = (event) => {
    setSelectedOptimizer(event.target.value);
    const optimizerData = optimizers.find((optimizer) => optimizer.id === event.target.value);
    if (optimizerData) {
      setOptimizerData(optimizerData);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Get states and cities based on the selected enterprise and state
  const selectedEnterpriseData = enterprises.find(
    (enterprise) => enterprise.entepriseId === selectedEnterprise
  );
  const countrystates = selectedEnterpriseData
    ? selectedEnterpriseData.states
    : [];
  const selectedCountryStateData = countrystates.find(
    (countrystate) => countrystate.stateId === selectedCountryState
  );
  const locations = selectedCountryStateData
    ? selectedCountryStateData.locations
    : [];
  const selectedLocationData = locations.find(
    (location) => location.locId === selectedLocation
  );
  const gateways = selectedLocationData ? selectedLocationData.gateways : [];
  const selectedGatewayData = gateways.find(
    (gateway) => gateway._id === selectedGateway
  );
  const optimizers = selectedGatewayData ? selectedGatewayData.optimizers : [];

  return (
    <div className="mb-2 flex space-x-3 p-3">
      <label className="block mt-4 text-sm w-56">
        <span className="text-gray-700 dark:text-gray-400">
          Customer <span style={{ color: "red" }}> *</span>
        </span>
        <select
          className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
          name="customer"
          onChange={handleEnterpriseChange}
          value={selectedEnterprise}
          disabled={ formDisabled ||
            selectedTab === "availableReports" || selectedTab === "newReports"
          }
        >
          <option value="">Select Enterprise</option>
          {enterprises.map((enterprise) => (
            <option
              key={enterprise.enterpriseName}
              value={enterprise.entepriseId}
            >
              {enterprise.enterpriseName}
            </option>
          ))}
        </select>
      </label>

      <label className="block mt-4 text-sm w-56">
        <span className="text-gray-700 dark:text-gray-400">
          State <span style={{ color: "red" }}> *</span>
        </span>
        <select
          className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
          name="state"
          onChange={handleCountryStateChange}
          value={selectedCountryState}
          disabled={formDisabled || !selectedEnterprise}
        >
          <option value="">Select State</option>
          {countrystates.map((countrystate) => (
            <option key={countrystate.stateName} value={countrystate.stateId}>
              {countrystate.stateName}
            </option>
          ))}
        </select>
      </label>

      <label className="block mt-4 text-sm w-56">
        <span className="text-gray-700 dark:text-gray-400">
          Location <span style={{ color: "red" }}> *</span>
        </span>
        <select
          className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
          name="location"
          onChange={handleLocationChange}
          value={selectedLocation}
          disabled={formDisabled || !selectedCountryState}
        >
          <option value="">Select Location</option>
          {locations.map((location) => (
            <option key={location.locId} value={location.locId}>
              {location.locationName}
            </option>
          ))}
        </select>
      </label>

      {selectedTab !== "availableReports" && (
        <label className="block mt-4 text-sm w-56">
          <span className="text-gray-700 dark:text-gray-400">
            Gateway Id{" "}
            {selectedTab !== "newReports" && (
              <span style={{ color: "red" }}> *</span>
            )}
          </span>
          <select
            className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
            name="gatewayId"
            onChange={handleGatewayChange}
            value={selectedGateway}
            disabled={formDisabled || !selectedLocation}
          >
            <option value="">Select Gateway</option>
            {gateways.map((gateway) => (
              <option key={gateway.gatewayId} value={gateway._id}>
                {gateway.gatewayId}
              </option>
            ))}
          </select>
        </label>
      )}

      {selectedTab !== "availableReports" && !hideOptimizer && (
        <label className="block mt-4 text-sm w-56">
          <span className="text-gray-700 dark:text-gray-400">Optimizer Id</span>
          <select
            className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
            name="optimizerId"
            onChange={handleOptimizerChange}
            value={selectedOptimizer}
            disabled={formDisabled || !selectedGateway || isOptimizerDisabled}
          >
            <option value="">Select Optimizer</option>
            {optimizers.map((optimizer) => (
              <option key={optimizer.id} value={optimizer.id}>
                {optimizer.nameId}
              </option>
            ))}
          </select>
        </label>
      )}
    </div>
  );
}

export default NewReports;
