import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LeftMenuList from "../Common/LeftMenuList";
import TopNavbar from "../Common/TopNavbar";
import "daterangepicker";
import moment from "moment";
import $ from "jquery";
import NewReports from "./Report/EnterpriseData";
import { Configs, EnergyMeter, AmbientInfo, BypassData, AcStatus, DeloitteLinks,} from "../Slices/DeloitteDataSlice";
import { useTable, usePagination } from "react-table";
import Loader from "../utils/Loader";
import Pagination from "../Common/Pagination";

const TableWithColumns = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    canPreviousPage,
    canNextPage,
    page,
    gotoPage,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Default page size
    },
    usePagination
  );

  const downloadCSV = () => {
    const headers = columns.map((col) => col.Header);
    const rows = data.map((row) =>
      columns.map((col) => row[col.accessor] || "")
    ); // Adjust this according to your column setup

    // Create CSV string
    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    // Create a Blob with CSV data and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "table_data.csv");
    link.click();
    URL.revokeObjectURL(url); // Clean up
  };

  // Calculate start and end index for current page
  const startIndex = pageIndex * pageSize;
  const endIndex = Math.min(startIndex + pageSize, data?.length || 0);

  // Calculate the range of page numbers to show
  const maxPageButtons = 8;
  const halfRange = Math.floor(maxPageButtons / 2);
  let startPage = Math.max(pageIndex - halfRange, 0);
  let endPage = Math.min(pageIndex + halfRange, pageCount - 1);

  // Adjust range if fewer than maxPageButtons are available
  if (endPage - startPage + 1 < maxPageButtons) {
    if (startPage === 0) {
      endPage = Math.min(maxPageButtons - 1, pageCount - 1);
    } else {
      startPage = Math.max(pageCount - maxPageButtons, 0);
    }
  }

  return (
    <div style={{ overflowX: "auto" }}>
      <table
        {...getTableProps()}
        className="min-w-full divide-y divide-gray-200 table-auto dark:divide-gray-700"
        style={{ tableLayout: "fixed", width: "100%" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="px-4 py-2 text-left text-sm font-medium text-gray-700 uppercase tracking-wider dark:text-gray-300"
                  style={{ wordBreak: "break-word" }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700"
        >
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="px-4 py-2 text-sm text-gray-500 dark:text-gray-300"
                    style={{ wordBreak: "break-word" }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination */}
      <Pagination
        tableData={data}
        itemsPerPage={pageSize}
        onPageChange={(newPage) => gotoPage(newPage - 1)}
        currentPage={pageIndex + 1}
      />
    </div>
  );
};

const Deloittedata = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userType, setUserType] = useState("meterData");
  const [settingsComplete, setSettingsComplete] = useState(false);
  const dateRangePickerRef = useRef(null); // Create a ref for the input element
  const [selectedTab, setSelectedTab] = useState("availableReports");

  const [selectedEnterprise, setSelectedEnterprise] = useState("");
  const [selectedCountryState, setSelectedCountryState] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedGateway, setSelectedGateway] = useState("");
  const [selectedOptimizer, setSelectedOptimizer] = useState("");
  const [selectedAPI, setSelectedAPI] = useState(""); // To store the selected API
  const [errorFetchingData, setErrorFetchingData] = useState("");
  const [errors, setErrors] = useState({
    country: false,
    state: false,
    city: false,
    optimizers: false,
    dates: false,
  });
  const [formDisabled, setFormDisabled] = useState(false);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedMonth, setSelectedMonth] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [tableData, setTableData] = useState([]);
  const currentYear = moment().year();
  const currentMonth = moment().month() + 1;
  const yearOptions = Array.from({ length: 5 }, (_, i) => currentYear - 3 + i);
  const [columns, setColumns] = useState([]);
  const [availableReports, setAvailableReports] = useState([]);

  const [gatewayData, setGatewayData] = useState(null);
  const [optimizerData, setOptimizerData] = useState(null);

  const { loading, response, error } = useSelector(
    (state) => state.deloitteDataSlice
  );

  const header = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
  };

  const lockUnlockSettings = (event) => {
    const newErrors = {
      country: selectedEnterprise === "",
      state: selectedCountryState === "",
      location: selectedLocation === "",
      gateway: selectedGateway === "",
      startDate: startDate === "",
      endDate: endDate === "",
    };
    // meterData usageTrend deviceData
    setErrors(newErrors);
    if (
      newErrors.country ||
      newErrors.state ||
      newErrors.location ||
      newErrors.gateway ||
      newErrors.endDate ||
      newErrors.startDate
    ) {
      newErrors.message =
        "Customer, State, Location, Gateway, Date is required. Did you forget to apply the dates";
    }

    if (!newErrors.message) {
      if (formDisabled) {
        setFormDisabled(false);
        setSettingsComplete(false);
      } else {
        setFormDisabled(true);
        setSettingsComplete(true);
      }
    }
  };

  useEffect(() => {
    if (!selectedAPI || !trigger) {
      setTableData([]);
      return; // Don't fetch data if no API or trigger is false
    }

    const Data = {
      startTime: startDate,
      endTime: endDate,
      LocationId: selectedLocation,
      GatewayId: selectedGateway,
    };

    // Add specific properties based on the selected API
    if (["AmbientInfo", "Bypass", "AcOnOff"].includes(selectedAPI)) {
      Data.OptimizerId = selectedOptimizer;
    }

    if (["GatewayConfig", "OptimizerConfig"].includes(selectedAPI)) {
      Data.type = selectedAPI === "GatewayConfig" ? "Gateway" : "Optimizer";
    }

    const apiDispatchMap = {
      EnergyMeter: EnergyMeter,
      AmbientInfo: AmbientInfo,
      Bypass: BypassData,
      AcOnOff: AcStatus,
      GatewayConfig: Configs,
      OptimizerConfig: Configs,
    };

    const apiAction = apiDispatchMap[selectedAPI];

    // API Data Mapper
    const dataMapper = {
      EnergyMeter: (data) =>
        data.map((item) => ({
          gatewayId: item.GatewayId,
          timestamp: moment.unix(item.TimeStamp).format("YYYY-MM-DD HH:mm:ss"),
          kwh: item.KWH,
          kvah: item.KVAH,
          pf: item.PF,
          type: item.Type,
        })),
      GatewayConfig: (data) =>
        data.map((item) => ({
          gatewayId: item.GatewayId,
          time: moment(item.Time).format("YYYY-MM-DD HH:mm:ss"),
          onboardingDate: moment(item.OnboardingDate).format("YYYY-MM-DD"),
          action: item.Action,
          type: item.Type,
        })),
      AmbientInfo: (data) =>
        data.map((item) => ({
          gatewayId: item.GID,
          optimizerId: item.OID,
          time: moment.unix(item.TimeStamp).format("YYYY-MM-DD HH:mm:ss"),
          tempUnit: item.TempUT,
          ambTemp: item.AmbTemp,
          ambHum: item.AmbHum,
          type: item.Type,
        })),
      Bypass: (data) =>
        data.map((item) => ({
          gatewayId: item.GatewayId,
          optimizerId: item.OptimizerId,
          time: moment(item.Time).format("YYYY-MM-DD HH:mm:ss"),
          status: item.Status,
          type: item.Type,
        })),
      AcOnOff: (data) =>
        data.map((item) => ({
          gatewayId: item.GatewayId,
          optimizerId: item.OptimizerId,
          time: moment(item.Time).format("YYYY-MM-DD HH:mm:ss"),
          event: item.Event,
          type: item.Type,
        })),
      OptimizerConfig: (data) =>
        data.map((item) => ({
          gatewayId: item.GatewayId,
          optimizerId: item.OptimizerId,
          time: moment(item.Time).format("YYYY-MM-DD HH:mm:ss"),
          acTonnage: item.Details.ACTonnage,
          acEnergy: item.Details.AC_Energy,
          action: item.Action,
          type: item.Type,
        })),
    };

    if (apiAction) {
      dispatch(apiAction({ data: Data, header }))
        .then((response) => {
          const fetchedList = response.payload;
          if (fetchedList && fetchedList.data && fetchedList.data.length > 0) {
            const mappedData = dataMapper[selectedAPI]
              ? dataMapper[selectedAPI](fetchedList.data)
              : [];
            setTableData(mappedData);
            setErrorFetchingData(""); // Clear any previous error message
          } else {
            setTableData([]);
            setErrorFetchingData(
              "No data available for the selected criteria."
            ); // Set the error message here
            setTimeout(() => setErrorFetchingData(""), 5000);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setErrors({ message: "Error fetching data" });
          setErrorFetchingData(""); // Clear errorFetchingData on a different type of error
          setTimeout(() => setErrorFetchingData(""), 5000);
        });
      setTrigger(false); // Reset the trigger after dispatch
    } else {
      setErrors({ message: "Invalid API selection" });
    }
  }, [
    selectedAPI,
    startDate,
    endDate,
    selectedLocation,
    selectedGateway,
    selectedOptimizer,
    dispatch,
    trigger,
  ]);

  // Date and Time
  useEffect(() => {
    if ($(dateRangePickerRef.current).data("daterangepicker")) {
      $(dateRangePickerRef.current).data("daterangepicker").remove();
    }

    let minDate, maxDate, startDate, endDate;

    if (selectedTab === "newReports") {
      // Behavior for newReports (current month)
      minDate = moment().startOf("year"); // Allow selection from the start of the current year
      maxDate = moment().endOf("day"); // End of the current day
      startDate = moment().startOf("month"); // Default start date is the start of the current month
      endDate = moment(); // Default end date is the current date
    }

    $(dateRangePickerRef.current).daterangepicker({
      timePicker: true,
      singleMonthPicker: false, // Allow navigation to past months if needed
      startDate: startDate, // Default start date
      endDate: endDate, // Default end date
      minDate: minDate, // Allow any date from the start of the current year
      maxDate: maxDate, // Set maximum date as today
      locale: {
        format: "M/DD/YYYY hh:mm A",
      },
      isInvalidDate: function (date) {
        // No date is invalid, only restrict future dates
        return date.isAfter(maxDate); // Disable future dates
      },
    });

    $(".daterangepicker .calendar-time").css({
      "pointer-events": "none",
      cursor: "not-allowed",
      opacity: "0.5",
    });

    // Update the state when a date is applied
    $(dateRangePickerRef.current).on("apply.daterangepicker", (ev, picker) => {
      const startTimestamp = picker.startDate.unix();
      const endTimestamp = picker.endDate.unix();
      setStartDate(startTimestamp);
      setEndDate(endTimestamp);
    });

    return () => {
      if ($(dateRangePickerRef.current).data("daterangepicker")) {
        $(dateRangePickerRef.current).data("daterangepicker").remove();
      }
    };
  }, [userType, selectedTab]);

  // Include selectedTab to reinitialize on tab change

  const handleApply = () => {
    // Ensure all required fields are selected before fetching data
    const newErrors = {
      enterprise: selectedEnterprise === "",
      state: selectedCountryState === "",
      location: selectedLocation === "",
      api: selectedAPI === "",
    };

    // Set an error message if any required fields are missing
    if (Object.values(newErrors).some((val) => val)) {
      setErrors({ message: "Please fill in all required fields." });

      setTimeout(() => {
        setErrors({});
      }, 5000);

      return; // Exit if there are missing fields
    }

    // Clear previous errors and set trigger for data fetch
    setErrors({});
    setTrigger(true);
    setColumns(apiColumnsMap[selectedAPI] || []);
  };

  useEffect(() => {
    if (selectedTab === "newReports") {
      // Only reset fields that need to be cleared for the newReports tab
      setSelectedCountryState(""); // Reset state selection
      setSelectedLocation(""); // Reset location selection
      setSelectedGateway(""); // Reset gateway selection
      setSelectedOptimizer(""); // Reset optimizer selection
      setStartDate(""); // Reset start date
      setEndDate(""); // Reset end date
      setSelectedAPI(""); // Reset API selection
      setErrors({
        country: false,
        state: false,
        city: false,
        optimizers: false,
        dates: false,
      });
      setAvailableReports([]); // Reset available reports for newReports tab
      setSelectedMonth(""); // Reset selected month
      setSelectedYear(moment().year()); // Reset year to current year
    } else if (selectedTab === "availableReports") {
      // Do not reset `selectedEnterprise` for availableReports tab
      resetFormFields({ preserveEnterprise: true });
    }
  }, [selectedTab]);

  const resetFormFields = ({ preserveEnterprise = false } = {}) => {
    // Reset fields, but allow `selectedEnterprise` to be preserved if needed
    if (!preserveEnterprise) {
      setSelectedEnterprise("");
    }
    setSelectedCountryState("");
    setSelectedLocation("");
    setSelectedGateway("");
    setSelectedOptimizer("");
    setErrors({
      country: false,
      state: false,
      city: false,
      optimizers: false,
      dates: false,
    });
  };
  // Map API to columns
  const apiColumnsMap = {
    EnergyMeter: [
      { Header: "Gateway ID", accessor: "gatewayId" },
      { Header: "Timestamp", accessor: "timestamp" },
      { Header: "KWh", accessor: "kwh" },
      { Header: "KVAh", accessor: "kvah" },
      { Header: "PF", accessor: "pf" },
      { Header: "Type", accessor: "type" },
    ],
    AcOnOff: [
      { Header: "Gateway ID", accessor: "gatewayId" },
      { Header: "Optimizer ID", accessor: "optimizerId" },
      { Header: "Time", accessor: "time" },
      { Header: "Event", accessor: "event" },
      { Header: "Type", accessor: "type" },
    ],
    AmbientInfo: [
      { Header: "Gateway ID", accessor: "gatewayId" },
      { Header: "Optimizer ID", accessor: "optimizerId" },
      { Header: "Time", accessor: "time" },
      { Header: "Temp Unit", accessor: "tempUnit" },
      { Header: "Ambient Temp", accessor: "ambTemp" },
      { Header: "Ambient Humidity", accessor: "ambHum" },
      { Header: "Type", accessor: "type" },
    ],
    Bypass: [
      { Header: "Gateway ID", accessor: "gatewayId" },
      { Header: "Optimizer ID", accessor: "optimizerId" },
      { Header: "Time", accessor: "time" },
      { Header: "Status", accessor: "status" },
      { Header: "Type", accessor: "type" },
    ],
    GatewayConfig: [
      { Header: "Gateway ID", accessor: "gatewayId" },
      { Header: "Time", accessor: "time" },
      { Header: "Onboarding Date", accessor: "onboardingDate" },
      { Header: "Action", accessor: "action" },
      { Header: "Type", accessor: "type" },
    ],
    OptimizerConfig: [
      { Header: "Gateway ID", accessor: "gatewayId" },
      { Header: "Optimizer ID", accessor: "optimizerId" },
      { Header: "Time", accessor: "time" },
      { Header: "AC Tonnage", accessor: "acTonnage" },
      { Header: "AC Energy", accessor: "acEnergy" },
      { Header: "Action", accessor: "action" },
      { Header: "Type", accessor: "type" },
    ],
  };

  // Add this function inside your Deloittedata component
  const handleAvailbleReports = () => {
    // Check if any required fields are missing
    const isEnterpriseEmpty = selectedEnterprise === "";
    const isStateEmpty = selectedCountryState === "";
    const isLocationEmpty = selectedLocation === "";
    const isYearEmpty = selectedYear === "";
    const isMonthEmpty = selectedMonth === "";

    // Create a single error message if needed
    let errorMessage = "";

    if (
      isEnterpriseEmpty ||
      isStateEmpty ||
      isLocationEmpty ||
      isYearEmpty ||
      isMonthEmpty
    ) {
      errorMessage = "Please fill in all required fields.";
    }

    // Set the error message if there's a validation issue
    if (errorMessage) {
      setErrors({ message: errorMessage });

      setTimeout(() => {
        setErrors({});
      }, 5000);

      return; // Exit if there are missing fields
    }

    // Clear any previous errors
    setErrors({});

    // Proceed with fetching reports
    const monthYear = `${moment(selectedMonth, "M").format(
      "MMMM"
    )}_${selectedYear}`;
    const data = {
      LocationId: selectedLocation,
      MonthYear: monthYear,
    };

    dispatch(DeloitteLinks({ data, header })).then((response) => {
      const fetchedList = response.payload;
      if (fetchedList && fetchedList.data) {
        if (fetchedList.data.length > 0) {
          setAvailableReports(fetchedList.data);
        } else {
          // No data found
          setAvailableReports([]);
          setErrors({
            message: "No data available for the selected criteria.",
          });

          setTimeout(() => {
            setErrors({});
          }, 5000);
        }
      } else {
        setAvailableReports([]);
        setErrors({ message: "No data available for the selected criteria." });

        setTimeout(() => {
          setErrors({});
        }, 5000);
      }
    });
  };

  const downloadCSV = () => {
    if (tableData.length === 0) {
      setErrorFetchingData("No data available to download.");
      return; // Exit if no data is available
    }

    // Convert tableData to CSV format
    const headers = Object.keys(tableData[0]); // Assuming all objects have the same keys
    const rows = tableData.map((row) => headers.map((header) => row[header]));

    // Add headers to the beginning of the rows
    const csvContent = [
      headers.join(","), // CSV header
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a download link for the CSV file
    const link = document.createElement("a");
    const fileName = `deloitte_data_${moment().format("YYYYMMDD_HHmmss")}.csv`;

    if (navigator.msSaveBlob) {
      // For IE
      navigator.msSaveBlob(blob, fileName);
    } else {
      // For other browsers
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
        <LeftMenuList />
        <div className="flex flex-col flex-1 w-full">
          <TopNavbar />
          <main className="h-full overflow-y-auto">
            <div className="container grid px-6 mx-auto">
              <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                Deloitte Dataset
              </h2>
              <div className="tabs mb-2">
                <button
                  onClick={() => {
                    resetFormFields(); // Reset form fields
                    setSelectedTab("availableReports"); // Set tab to Available Reports
                  }}
                  className={`px-4 py-2 ${
                    selectedTab === "availableReports"
                      ? "bg-purple-600 text-white"
                      : "bg-gray-200"
                  }`}
                >
                  Available Reports
                </button>
                <button
                  onClick={() => {
                    setSelectedTab("newReports"); // Set tab to New Reports
                  }}
                  className={`px-4 py-2 ${
                    selectedTab === "newReports"
                      ? "bg-purple-600 text-white"
                      : "bg-gray-200"
                  }`}
                >
                  New Reports
                </button>
              </div>

              {selectedTab === "availableReports" && (
                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-xs relative">
                  {errors.message && (
                    <span style={{ color: "red" }} className="ml-2">{errors.message}</span>
                  )}
                  <form action="" id="report_form" className="mt-0">
                    <NewReports
                      selectedEnterprise={selectedEnterprise}
                      selectedCountryState={selectedCountryState}
                      selectedLocation={selectedLocation}
                      selectedGateway={selectedGateway}
                      selectedOptimizer={selectedOptimizer}
                      setSelectedEnterprise={setSelectedEnterprise}
                      setSelectedLocation={setSelectedLocation}
                      setSelectedCountryState={setSelectedCountryState}
                      setSelectedGateway={setSelectedGateway}
                      setSelectedOptimizer={setSelectedOptimizer}
                      formDisabled={formDisabled}
                      selectedTab={selectedTab}
                      setGatewayData={setGatewayData}
                      setOptimizerData={setOptimizerData}
                    />

                    <div className="mb-4 flex space-x-3 px-6">
                      {/* Year Dropdown */}
                      <div>
                        <label className="block text-sm">
                          <span className="text-gray-700 dark:text-gray-400">
                            Select Year <span style={{ color: "red" }}> *</span>
                          </span>
                          <select
                            value={selectedYear}
                            onChange={(e) => {
                              setSelectedYear(e.target.value);
                              setSelectedMonth("");
                            }}
                            className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray select-dropdown"
                            style={{ height: "38px" }}
                            disabled={formDisabled}
                          >
                            {/* Show the past two years and the current year in ascending order */}
                            {Array.from({ length: 3 }, (_, index) => {
                              const year = currentYear - (2 - index); // This ensures we show years in ascending order
                              return (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              );
                            })}

                            {/* Future years are disabled */}
                            {Array.from({ length: 10 }, (_, index) => {
                              const futureYear = currentYear + 1 + index;
                              return (
                                <option
                                  key={futureYear}
                                  value={futureYear}
                                  disabled
                                >
                                  {futureYear}
                                </option>
                              );
                            })}
                          </select>
                        </label>
                      </div>

                      {/* Month Dropdown */}
                      <div>
                        <label className="block text-sm">
                          <span className="text-gray-700 dark:text-gray-400">
                            Select Month{" "}
                            <span style={{ color: "red" }}> *</span>
                          </span>
                          <select
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value)}
                            className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray h-9 select-dropdown"
                            style={{ height: "38px" }}
                            disabled={formDisabled}
                          >
                            <option value="" disabled>
                              Select a month
                            </option>
                            {Array.from({ length: 12 }, (_, index) => {
                              const month = index + 1;
                              const isDisabled =
                                (selectedYear === currentYear &&
                                  month > currentMonth) || // Disable future months in the current year
                                selectedYear > currentYear; // Disable all months if the year is in the future
                              return (
                                <option
                                  key={month}
                                  value={month}
                                  disabled={isDisabled}
                                >
                                  {moment(month, "M").format("MMMM")}
                                </option>
                              );
                            })}
                          </select>
                        </label>
                      </div>

                      <div className="flex justify-between space-x-3 items-center">
                        <button
                          type="button"
                          onClick={handleAvailbleReports}
                          className="px-3 py-2 mt-3 focus:outline-none text-white rounded-lg bg-purple-600 active:bg-purple-600"
                        >
                          Apply
                        </button>
                      </div>
                      {/* <div className="flex justify-between space-x-3 items-center">
                      <button
                        type="button"
                        className="px-3 py-2 mt-3 focus:outline-none text-white rounded-lg bg-purple-600 active:bg-purple-600"
                      >
                        Download Available Reports
                      </button>
                    </div> */}
                    </div>
                  </form>
                  <hr className="ml-3 mr-3" />

                  {availableReports.length > 0 && (
                    <div className="mt-4">
                      <ul className="list-disc list-inside">
                        {availableReports.map((report, index) => (
                          <li key={index} className="mt-2">
                            <span>{index + 1}. </span>
                            <a
                              href={report.fileLink}
                              target="_blank"
                              download={`${report.typeOfReport.replace(
                                /\s+/g,
                                ""
                              )}_${report.monthYear}.csv`}
                              className="text-black hover:underline"
                            >
                              {report.typeOfReport.replace(/\s+/g, "") +
                                " " +
                                report.monthYear}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}

              {selectedTab === "newReports" && (
                <div className="w-full mb-8 overflow-hidden rounded-lg shadow-xs relative">
                  {errors.message && (
                    <span style={{ color: "red" }} className="ml-2">{errors.message}</span>
                  )}
                  <form action="" id="report_form">
                    <NewReports
                      selectedEnterprise={selectedEnterprise}
                      selectedCountryState={selectedCountryState}
                      selectedLocation={selectedLocation}
                      selectedGateway={selectedGateway}
                      selectedOptimizer={selectedOptimizer}
                      setSelectedEnterprise={setSelectedEnterprise}
                      setSelectedLocation={setSelectedLocation}
                      setSelectedCountryState={setSelectedCountryState}
                      setSelectedGateway={setSelectedGateway}
                      setSelectedOptimizer={setSelectedOptimizer}
                      formDisabled={formDisabled}
                      selectedTab={selectedTab}
                      setGatewayData={setGatewayData}
                      setOptimizerData={setOptimizerData}
                    />

                    <div className="mb-2 flex space-x-3 px-6">
                      {/* Date Range Picker */}
                      <div>
                        <label className="block text-sm">
                          <span className="text-gray-700 dark:text-gray-400">
                            Date <span style={{ color: "red" }}> *</span>
                          </span>
                          <input
                            ref={dateRangePickerRef}
                            name="datetimes"
                            className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                            placeholder="date end"
                            disabled={formDisabled}
                          />
                        </label>
                      </div>

                      {/* API Dropdown */}
                      <div>
                        <label className="block text-sm">
                          <span className="text-gray-700 dark:text-gray-400">
                            Select API <span style={{ color: "red" }}> *</span>
                          </span>
                          <select
                            value={selectedAPI}
                            onChange={(e) => setSelectedAPI(e.target.value)}
                            className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                            style={{
                              height: "38px", // Match the height of the date picker
                              minWidth: "200px", // Adjust width to align with date picker
                            }}
                            disabled={formDisabled}
                          >
                            <option value="">-- Select API --</option>
                            <option value="AcOnOff">Ac on/off</option>
                            <option value="AmbientInfo">Ambient info</option>
                            <option value="Bypass">Bypass</option>
                            <option value="EnergyMeter">Energy meter</option>
                            <option value="GatewayConfig">
                              Gateway config
                            </option>
                            <option value="OptimizerConfig">
                              Optimizer config
                            </option>
                          </select>
                        </label>
                      </div>
                      <div className="flex justify-between space-x-3 items-center">
                        <button
                          type="button"
                          onClick={handleApply}
                          className="px-3 py-2 mt-3 focus:outline-none text-white rounded-lg bg-purple-600 active:bg-purple-600"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="flex justify-end mr-6">
                    <button
                      type="button"
                      onClick={downloadCSV}
                      className="py-1 px-1 mt-2 focus:outline-none text-white rounded-lg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#4a90e2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
                      </svg>
                    </button>
                  </div>
                  <hr className="ml-3 mr-3" />
                  {columns.length > 0 && tableData.length > 0 ? (
                    <div className="w-full mt-6 overflow-hidden rounded-lg shadow-xs">
                      <TableWithColumns columns={columns} data={tableData} />
                    </div>
                  ) : errorFetchingData ? (
                    <div
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        textAlign: "center",
                        marginTop: "0.5rem",
                      }}
                    >
                      {errorFetchingData}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Deloittedata;
