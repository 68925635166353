import React, { useEffect, useState } from "react";
import LeftMenuList from "../../Common/LeftMenuList";
import TopNavbar from "../../Common/TopNavbar";
import "daterangepicker";
import { useSelector, useDispatch } from "react-redux";
import "react-circular-progressbar/dist/styles.css";
import { fetchEnterprises } from "../../Slices/Enterprise/NewEnterpriseDataSlice";
import { ByPassListing, clear_bypassschedule_response, clear_bypassonoff_response  } from "../../Slices/Enterprise/ByPassSlice";
import ByPassList from "./ByPassList";
import ScheduleByPassModal from "../../Modals/AddModals/ScheduleByPassModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from '../../utils/Loader';


function ByPass() {
  const dispatch = useDispatch();
  const { enterprises } = useSelector((state) => state.enterpriseDataSlice);
  const { loading, bypass_list_response, bypass_list_error } = useSelector((state) => state.byPassSlice);

  const header = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEnterprise, setSelectedEnterprise] = useState("");
  const [selectedCountryState, setSelectedCountryState] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedTimeRange, setSelectedTimeRange] = useState("");
  const [includeCompleted, setIncludeCompleted] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [settingsComplete, setSettingsComplete] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [bypassLevel, setBypassLevel] = useState("enterprise");
  const [hasApplied, setHasApplied] = useState(false);

  // page loading events - start

  useEffect(() => {
    dispatch(fetchEnterprises({ header }));
  }, [dispatch]);

  useEffect(() => {
    if (!selectedEnterprise) {
      setTableData([]);
      return;
    }
    if (trigger && selectedEnterprise) {
      const determinedBypassLevel = selectedLocation
        ? "location"
        : selectedCountryState
        ? "state"
        : "enterprise";

      const levelId =
        determinedBypassLevel === "enterprise"
          ? selectedEnterprise
          : determinedBypassLevel === "state"
          ? selectedCountryState || selectedEnterprise
          : selectedLocation || selectedEnterprise;

      const data = {
        bypassLevel: determinedBypassLevel,
        levelId,
        includeCompleted: includeCompleted,
        timeRange: selectedTimeRange,
      };

      dispatch(ByPassListing({ data, header })).then((response) => {
        const fetchedList = response.payload;
        if (fetchedList && fetchedList.data) {
          setTableData(fetchedList.data.length > 0 ? fetchedList.data : []);
        } else {
          setTableData([]);
        }
        setTrigger(false);
      });
    }
  }, [ trigger, selectedEnterprise, includeCompleted, selectedTimeRange, bypassLevel, dispatch,]);

  // page loading events - end
  const handleEnterpriseChange = (event) => {
    setSelectedEnterprise(event.target.value);
    setSelectedCountryState("");
    setSelectedLocation("");
    setBypassLevel("enterprise"); // Set bypass level to enterprise
  };

  const handleCountryStateChange = (event) => {
    const selectedState = event.target.value;
    setSelectedCountryState(selectedState);

    if (!selectedState) {
      // Reset location if state is unselected
      setSelectedCountryState("");
      setSelectedLocation("");
      setBypassLevel("enterprise"); // Shift bypass level to enterprise
    } else {
      setSelectedLocation("");
      setBypassLevel("state"); // Otherwise, set bypass level to state
    }
  };

  const handleLocationChange = (event) => {
    const selectedLoc = event.target.value;

    if (selectedCountryState) {
      // Allow location selection only if state is selected
      setSelectedLocation(selectedLoc);
      setBypassLevel("location"); // Set bypass level to location
    } else {
      // If state is not selected, do not allow location selection
      setSelectedLocation("");
      setBypassLevel("enterprise");
    }
  };

  const handleTimeRangeChange = (event) => {
    setSelectedTimeRange(event.target.value);
  };

  const handleIncludeCompletedChange = (event) => {
    setIncludeCompleted(event.target.checked);
  };

  // Get states and cities based on the selected country and state
  const selectedEnterpriseData = enterprises.find(
    (enterprise) => enterprise.entepriseId === selectedEnterprise
  );
  const countrystates = selectedEnterpriseData
    ? selectedEnterpriseData.states
    : [];
  const selectedCountryStateData = countrystates.find(
    (countrystate) => countrystate.state_id === selectedCountryState
  );
  const locations = selectedCountryStateData
    ? selectedCountryStateData.locations
    : [];
  const selectedLocationData = locations.find(
    (location) => location.locId === selectedLocation
  );

  const handleApply = () => {
    const errors = [];

    if (!selectedEnterprise) {
      errors.push("Enterprise selection is required.");
    }
    if (!includeCompleted && selectedTimeRange) {
      errors.push("Please select 'Include Completed Bypass' to view data for the selected time interval.");
    }
    if (includeCompleted && !selectedTimeRange) {
      errors.push("Time Range is required when including completed bypasses.");
    }

    if (errors.length > 0) {
      setErrorMessages(errors);

      setTimeout(() => {
        setErrorMessages([]);
      }, 3000);
  
      return;
    }

    // Clear errors if no validation issues
    setErrorMessages([]);
    setHasApplied(true);
    setTrigger(true);
  };

  // validation
  const lockUnlockSettings = (event) => {
    const newErrors = {
      country: selectedEnterprise === "",
      state: selectedCountryState === "",
      location: selectedLocation === "",
    };
    // meterData usageTrend deviceData
    setErrorMessages(newErrors);
    if (newErrors.country || newErrors.state || newErrors.location) {
      newErrors.message = "Country, State, Location is required";
    }

    if (!newErrors.message) {
      if (formDisabled) {
        setFormDisabled(false);
        setSettingsComplete(false);
      } else {
        setFormDisabled(true);
        setSettingsComplete(true);
      }
    }
  };

  // pop-up
  const showToast = (message, type) => {
    toast[type](message, {
      position: "bottom-left",
      autoClose: 3000,
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (hasApplied) {
      setTrigger(true); // Automatically refresh the list
    }
  };

  const handleDelete = () => {
    setTrigger(true);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <LeftMenuList />
      <div className="flex flex-col flex-1 w-full">
        <TopNavbar />

        <main className="h-full overflow-y-auto">
          <div className="container px-6 mx-auto grid">
            <div className="flex justify-between">
              <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                By Pass
              </h2>
              <div className="px-6 my-6">
                <button
                  onClick={openModal}
                  className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                >
                  Add ByPass
                  <span className="ml-2" aria-hidden="true">
                    {" "}
                    +
                  </span>
                </button>

                {isModalOpen && (
                  <ScheduleByPassModal
                    formDisabled={formDisabled}
                    // hideOptimizer={true}
                    closeModal={() => closeModal()}
                  />
                )}
              </div>
            </div>

            <div>
              <div className="mb-6">
                <form action="">
                  <div className="flex space-x-3 w-full ">
                    <label className="w-full block mt-4 text-sm flex justify-between space-x-3 items-center">
                      <span className="text-gray-700 dark:text-gray-400">
                        Enterprise
                      </span>
                      <select
                        className="w-full block mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                        onChange={handleEnterpriseChange}
                        value={selectedEnterprise}
                      >
                        <option value="">Select Enterprise</option>
                        {enterprises.map((enterprise) => (
                          <option
                            key={enterprise.enterpriseName}
                            value={enterprise.entepriseId}
                          >
                            {enterprise.enterpriseName}
                          </option>
                        ))}
                      </select>
                    </label>
                    <label className="w-full block mt-4 text-sm flex justify-between space-x-3 items-center">
                      <span className="text-gray-700 dark:text-gray-400">
                        State
                      </span>
                      <select
                        className="w-full block mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                        onChange={handleCountryStateChange}
                        value={selectedCountryState}
                        disabled={!selectedEnterprise}
                      >
                        <option value="">Select State</option>
                        {countrystates.map((countrystate) => (
                          <option
                            key={countrystate.stateName}
                            value={countrystate.state_id}
                          >
                            {countrystate.stateName}
                          </option>
                        ))}
                      </select>
                    </label>
                    <label className="w-full block mt-4 text-sm flex justify-between space-x-3 items-center">
                      <span className="text-gray-700 dark:text-gray-400">
                        Location
                      </span>
                      <select
                        className="w-full block mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                        onChange={handleLocationChange}
                        value={selectedLocation}
                        disabled={!selectedEnterprise}
                      >
                        <option value="">Select Location</option>
                        {locations.map((location) => (
                          <option key={location.locId} value={location.locId}>
                            {location.locationName}
                          </option>
                        ))}
                      </select>
                    </label>
                    {/* Checkbox for Include Completed Bypass */}
                    <label className="w-full block mt-4 text-sm flex space-x-3 items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox text-xl text-purple-600 dark:text-gray-300"
                        checked={includeCompleted}
                        onChange={handleIncludeCompletedChange}
                      />
                      <span className="text-gray-700 dark:text-gray-400">
                        Include Completed Bypass
                      </span>
                    </label>

                    {/* Dropdown for Time Range Selection */}
                    <label className="w-full block mt-4 text-sm flex justify-between space-x-3 items-center">
                      <select
                        className="block mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                        onChange={handleTimeRangeChange}
                        value={selectedTimeRange}
                      >
                        <option value="">Select Time Range</option>
                        <option value="1">Last 1 Month</option>
                        <option value="3">Last 3 Month</option>
                      </select>
                    </label>
                    <div className="flex justify-between space-x-3 items-center">
                      <button
                        type="button"
                        onClick={handleApply}
                        className="px-3 py-2 mt-3 focus:outline-none text-white rounded-lg bg-purple-600 active:bg-purple-600"
                      >
                        Apply
                      </button>
                    </div>
                  </div>

                  {/* Error Messages */}
                  {Array.isArray(errorMessages) && errorMessages.length > 0 && (
                    <div className="mt-2">
                      {errorMessages.map((msg, index) => (
                        <p className="text-red-600 text-center" key={index}> {msg} </p>
                      ))}
                    </div>
                  )}
                </form>
              </div>
            </div>
            <hr />

            <ByPassList bypassList={tableData} onUpdatingList={handleDelete} />


            <hr />
          </div>
        </main>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        //  transition: Bounce
      />

      </div>
    </>
  );
}

export default ByPass;
