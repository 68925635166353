import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ENERGYMETERAPI } from '../api/api'; // Assuming you have the ENERGYMETER API function

// Thunk to fetch EnergyMeter data
export const EnergyMeterApi = createAsyncThunk(
  'EnergyMeterApi',
  async ({ data, header }, { rejectWithValue }) => {
    try {
      const response = await ENERGYMETERAPI(data, header);
      return response.data;
    } catch (error) {
      if (
        error.response.data.message === 'Invalid token' ||
        error.response.data.message === 'Access denied'
      ) {
        window.localStorage.clear();
        window.location.href = './';
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// EnergyMeter Slice
export const energyMeterSlice = createSlice({
  name: 'energyMeterSlice',
  initialState: {
    status: "",
    loading: false,

    response: "",
    error: null,

  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearResponse: (state) => {
      state.response = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(EnergyMeterApi.pending, (state) => {
        state.status = "Loading...";
        state.loading = true;
      })
      .addCase(EnergyMeterApi.fulfilled, (state, { payload }) => {
        state.status = "Success";
        state.loading = false;
        state.response = payload;
        state.bypass_error = null;
      })
      .addCase(EnergyMeterApi.rejected, (state, { payload }) => {
        state.status = "Failed";
        state.loading = false;
        state.error = payload;
      })
  },
});

// Export actions
export const { clearError, clearResponse } = energyMeterSlice.actions;

export default energyMeterSlice.reducer;
