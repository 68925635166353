import React, { useState, useEffect } from "react";
import LeftMenuList from "../Common/LeftMenuList";
import TopNavbar from "../Common/TopNavbar";
import UserModal from "../Modals/AddModals/UserModal";
import {
  userList,
  clearSystemIntegratorResponse,
  clearEnterpriseResponse,
  clearDeleteResponse,
} from "../Slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../utils/Loader";
import UserDeleteModal from "../Modals/DeleteModals/UserdeleteModel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from '../Common/Pagination';

function User() {
  const dispatch = useDispatch();
  const [isModalOpenAddUser, setIsModalOpenAddUser] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [trigger, setTrigger] = useState(true);
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);

  // // Redux selectors
  const { response, loading } = useSelector((state) => state.userSlice);
  const { delete_response } = useSelector((state) => state.userSlice);
  const { add_SyetemIntegrator, add_enterprise_user } = useSelector(
    (state) => state.userSlice
  );

  // Header for API calls
  const header = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
  };

  const openDeleteModal = (item) => {
    setSelectedDeleteItem(item._id);
    setIsDeleteModelOpen(true);
  };

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const paginationRange = 1;
  
  const displayedData = reportData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const openModalAddUser = () => {
    setIsModalOpenAddUser(true);
  };

  // Function to close modals and reset states
  const closeModalAddUser = () => {
    setTrigger(true);
    setIsModalOpenAddUser(false);
    setIsDeleteModelOpen(false);
  };

  //Api Calling
  useEffect(() => {
    if (trigger) {
      dispatch(userList({ header }));
      // Reset trigger to prevent continuous API calls
      setTrigger(false);
    }
  }, [trigger, closeModalAddUser, delete_response, dispatch, header]);

  // Handling API responses
  useEffect(() => {
    if (add_SyetemIntegrator) {
      showToast(add_SyetemIntegrator.data.message, "success");
      dispatch(clearSystemIntegratorResponse());
    }
    if (add_enterprise_user) {
      showToast(add_enterprise_user.data.message, "success");
      dispatch(clearEnterpriseResponse());
    }
    if (delete_response) {
      showToast(delete_response.message, "success");
      dispatch(clearDeleteResponse());
    }
    if (response && Array.isArray(response)) {
      setReportData(response);
    }
  }, [trigger, response, delete_response, dispatch, add_SyetemIntegrator, add_enterprise_user,]);

  // Function to show toast messages
  const showToast = (message, type) => {
    toast[type](message, {
      position: "bottom-left",
      autoClose: 2000,
    });
  };

  return (
    <>
      {loading && <Loader />}
      <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
        <LeftMenuList />
        <div className="flex flex-col flex-1 w-full">
          <TopNavbar />

          <main className="h-full overflow-y-auto">
            <div className="container px-6 mx-auto grid">
              <div className="flex justify-between">
                <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                  User
                </h2>
                <div className="px-6 my-6">
                  <button
                    //   @click="openModaladduser"

                    onClick={openModalAddUser}
                    className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                  >
                    Add User
                    <span className="ml-2" aria-hidden="true">
                      +
                    </span>
                  </button>

                  {isModalOpenAddUser && (
                    <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
                      {/* <!-- Modal --> */}

                      <UserModal closeModal={() => closeModalAddUser()} />
                    </div>
                  )}
                </div>
              </div>

              {/* <!-- table --> */}

              <div className="w-full overflow-x-auto">
                <table className="w-full whitespace-no-wrap">
                  <thead>
                    <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                      <th className="px-4 py-3">User Name</th>
                      <th className="px-4 py-3">User Type</th>
                      <th className="px-4 py-3">User Email Id</th>
                      <th className="px-4 py-3">User phone Number</th>
                      <th className="px-4 py-3">enterprise Name</th>
                      <th className="px-4 py-3">Action</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                    {displayedData.map((item, index) => (
                      <tr
                        className="text-gray-700 dark:text-gray-400"
                        key={index}
                      >
                        <td className="px-4 py-3">{item.username}</td>

                        <td className="px-4 py-3">{item.type}</td>
                        <td className="px-4 py-3 text-sm">{item.email}</td>

                        {item.type === "System-integrator" && (
                          <td className="px-4 py-3 text-sm">
                            <b>+91</b> {item.systemIntegratorId?.phone}
                          </td>
                        )}
                        {item.type === "EnterpriseUser" && (
                          <td className="px-4 py-3 text-sm">
                            <b>+91</b> {item.enterpriseUserId?.phone}
                          </td>
                        )}

                        {item.type === "System-integrator" && (
                          <td className="px-4 py-3">
                            <div className="flex items-center text-sm">
                              <div>
                                <p className="font-semibold">----</p>
                              </div>
                            </div>
                          </td>
                          // -------------------------------------------------------------------------------------------------------
                        )}
                        {item.type === "EnterpriseUser" && (
                          <td className="px-4 py-3">
                            <div className="flex items-center text-sm">
                              <div>
                                <p className="font-semibold">
                                  {item.enterpriseUserId?.username}
                                </p>
                              </div>
                            </div>
                          </td>
                          
                        )}
                        <td className="px-4 py-3 text-sm" >
                          <button
                            className="px-2 py-2 border-2 border-red-600 text-purple-600 rounded-md"
                            onClick={() => openDeleteModal(item)}
                          >
                              <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="red"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                          </button>
                          {isDeleteModelOpen && (
                            <div
                              className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
                              // onClick={closeModal}
                            >
                              <UserDeleteModal
                                closeModal={() => closeModalAddUser()}
                                Id={selectedDeleteItem}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* Pagination  */}
              <Pagination
                tableData={reportData}
                itemsPerPage={itemsPerPage}
                onPageChange={(page) => setCurrentPage(page)}
                currentPage={currentPage}
              />
            </div>
          </main>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          //  transition: Bounce
        />
      </div>
    </>
  );
}

export default User;