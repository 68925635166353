import React, { useState, useEffect } from "react";
import LeftMenuList from "../Common/LeftMenuList";
import TopNavbar from "../Common/TopNavbar";
import EnterpriseModal from "../Modals/AddModals/EnterpriseModal";
import EnterpriseEditModal from "../Modals/EditModals/EnterpriseEditmodel";
import StateModals from "../Modals/AddModals/StateModals";
import LocationModals from "../Modals/AddModals/LocationModals";
import GatewayModals from "../Modals/AddModals/GatewayModals";
import OptimizerModel from "../Modals/AddModals/OptimizerModel";
import OptimizerEditModal from "../Modals/EditModals/OptimizerEditModal";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchEnterprises, setEnterprisesChanges } from "../Slices/Enterprise/NewEnterpriseDataSlice";
import { clearDelete_response, clearAdd_enterprise_response, clearEdit_enterprise_response,} from "../Slices/Enterprise/enterpriseSlice";
import { clearAdd_state_response, clearResponse, clearError } from "../Slices/Enterprise/StateSlices";
import { clearAddLoctation_response } from "../Slices/Enterprise/LocationSlice";
import { clearEdit_gateway_response, clearGatewayResponse,} from "../Slices/Enterprise/GatewaySlice";
import { clearEditOptimizerResponse, clearAddOptimizerResponse,} from "../Slices/Enterprise/OptimizerSlice";
import DeleteModal from "../Modals/DeleteModals/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../utils/Loader";
import axios from "axios";


function EnterprisesList() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enterpriseTableData, setEnterpriseTableData] = useState([]);
  const [selectedIds, setSelectedIds] = useState({
    enterprise: null,
    state: null,
    location: null,
    gateway: null,
    optimizer: null,
  });
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [trigger, setTrigger] = useState(true);
  const [modalType, setModalType] = useState(null);
  const [isEditModelOpen, setIsEditModelOpen] = useState(false);
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const header = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
  };

  const { status, add_enterprise_response, edit_enterprise_response, customer_response, allDelete_response, allDelete_error, error, loading,} = useSelector((state) => state.enterpriseSlice);
  const { enterprises } = useSelector((state) => state.enterpriseDataSlice);
  const { add_state_response} = useSelector((state) => state.stateSlices);
  const { add_locationlist_response } = useSelector((state) => state.locationSlice);
  const { edit_gateway_response, add_gatewaylist_response } = useSelector((state) => state.gatewaySlice);
  const { add_optimizerlist_response, edit_optimizer_response } = useSelector((state) => state.optimizerSlice);

  useEffect(() => {
    dispatch(fetchEnterprises({ header }));
  }, [dispatch]);

  // Update enterpriseTableData when enterprises are fetched
  useEffect(() => {
    if (enterprises && Array.isArray(enterprises)) {
      setEnterpriseTableData(enterprises);
    }
  }, [enterprises]);

  const openModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const handleInputChange = async (item, route) => {
    if (route === "/optimizer") {
      window.localStorage.setItem("Optimizer_id", item.nameId);
      return <Link to="/optimizerdetails"> </Link>;
    } else if (route === "/gateway") {
      window.localStorage.setItem("Gateway_id", item.gatewayId);
      return <Link to="/gatewaydetails"> </Link>;
    }
  };

  const openEditModal = (item) => {
    setSelectedItem(item);
    setIsEditModelOpen(true);
  };

  const closeModal = () => {
    setTrigger(true);
    setIsModalOpen(false);
    setIsEditModelOpen(false);
    setIsDeleteModelOpen(false);
  };

  const openDeleteModal = (item, type) => {
    const deleteDataMap = {
      enterprise: { group: "enterprise", id: item.entepriseId },
      state: { group: "state", id: item.state_id }, 
      location: { group: "location", id: item.locId },
      gateway: { group: "gateway", id: item._id },
      optimizer: { group: "optimizer", id: item.id },
    };
  
    const deleteData = deleteDataMap[type] || null;
    console.log(deleteData);
    setSelectedDeleteItem(deleteData);
    setIsDeleteModelOpen(true);
  };

  const resetSelectedStates = () => {
    setSelectedIds({
      enterprise: null,
      state: null,
      location: null,
      gateway: null,
      optimizer: null,
    });
  };

  const handleSelect = (type, id) => {
    const resetMap = {
      enterprise: { state: null, location: null, gateway: null, optimizer: null },
      state: { location: null, gateway: null, optimizer: null },
      location: { gateway: null, optimizer: null },
      gateway: { optimizer: null },
    };
    setSelectedIds((prev) => ({
      ...prev, 
      [type]: id,
      ...resetMap[type],
    }));
  
    setModalType(type);
  };

  const getGatewayData = () => {
    const selectedEnterpriseData = enterprises.find(
      (enterprise) => enterprise.entepriseId === selectedIds.enterprise
    );
  
    const selectedStateData = selectedEnterpriseData?.states.find(
      (state) => state.stateId === selectedIds.state
    );
  
    const selectedLocationData = selectedStateData?.locations.find(
      (location) => location.locId === selectedIds.location
    );
  
    const selectedGateway = selectedLocationData?.gateways.find(
      (gateway) => gateway._id === selectedIds.gateway
    );
  
    return {
      gatewayId: selectedGateway ? selectedGateway.gatewayId : null,
      Enterprise: selectedEnterpriseData?.enterpriseName,
      EnterpriseUser: selectedEnterpriseData?.enterpriseName,
      State: selectedStateData?.stateName,
      Location: selectedLocationData?.locationName,
    };
  };
  
  // Usage in the component
  const { gatewayId, Enterprise, EnterpriseUser, State, Location } = getGatewayData();
  
  const renderBreadcrumbs = () => {
    const breadcrumbs = [
      { id: "enterprise", label: "Enterprise" },
      { id: "state", label: "State", dependency: "enterprise" },
      { id: "location", label: "Location", dependency: "state" },
      { id: "gateway", label: "Gateway", dependency: "location" },
      { id: "optimizer", label: "Optimizers", dependency: "gateway" },
    ];
    
    return (
      <nav className="flex px-5 py-3 text-white-700 bg-purple-600 dark:bg-gray-800 mb-4 rounded">
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          {breadcrumbs.map((crumb, index) => {

            const isVisible = !crumb.dependency || selectedIds[crumb.dependency];
  
            return (
              isVisible && (
                <li className="inline-flex items-center" key={crumb.id}>
                  {index > 0 && <span className="mx-1 text-gray-400">&gt;</span>}
                  <button
                    className="text-sm font-medium text-white hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                    onClick={() => handleSelect(crumb.id, null)}
                  >
                    {crumb.label}
                  </button>
                </li>
              )
            );
          })}
        </ol>
      </nav>
    );
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };
  
  const isGatewayValid = (gateway) => {
    return gateway.gatewayId !== null && !isEmptyObject(gateway);
  };

  const renderEnterpriseOrStateRows = () => {
    if (selectedIds.enterprise) {
      const selectedEnterpriseData = enterprises.find(
        (enterprise) => enterprise.entepriseId === selectedIds.enterprise
      );

      if (selectedIds.state) {
        const selectedStateData = selectedEnterpriseData.states.find(
          (state) => state.stateId === selectedIds.state
        );

        if (selectedIds.location) {
          const selectedLocationData = selectedStateData.locations.find(
            (location) => location.locId === selectedIds.location
          );

          if (selectedIds.gateway) {
            const selectedGatewayData = selectedLocationData.gateways.find(
              (gateway) => gateway._id === selectedIds.gateway
            );

            const optimizers = selectedGatewayData.optimizers.filter((optimizer) => !isEmptyObject(optimizer));
            const gatewayId = selectedGatewayData.gatewayId;
            return optimizers.length === 0 ? (
              <tr>
                <td colSpan={4} className="text-center text-gray-700 dark:text-gray-400 px-4 py-3">
                  No optimizers found for the given gateway ID.
                </td>
              </tr>
            ) : (
              optimizers.map((optimizer) => {
                return (
                  <tr key={optimizer.id} className="text-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700">
                    <td className="px-4 py-3 hover:underline text-sm text-blue-500 font-semibold ">{optimizer.nameId}</td>
                    <td className="px-4 py-3 text-sm">{optimizer.name}</td>
                    <td className="px-4 py-3 text-sm"></td>
                    <td className="flex space-x-2 px-4 py-3">
                      <button
                        onClick={() => openEditModal(optimizer)}
                        className="px-2 py-2 border-2 border-purple-600 text-purple-600 rounded-md"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentcolor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polygon points="16 3 21 8 8 21 3 21 3 16 16 3"></polygon>
                        </svg>
                      </button>

                      {isEditModelOpen && (
                        <div
                          className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
                          // onClick={closeModal}
                        >
                          <OptimizerEditModal
                            closeModal={() => closeModal()}
                            Data={{ GatewayId: gatewayId, selectedItem }}
                            selectedItem={selectedItem}
                          />
                        </div>
                      )}
                      
                      <button
                        className="px-2 py-2 border-2 border-red-600 text-purple-600 rounded-md"
                        onClick={() => openDeleteModal(optimizer, "optimizer")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="red"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                      </button>

                      {isDeleteModelOpen && (
                        <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
                          <DeleteModal
                            closeModal={() => closeModal()}
                            Data={selectedDeleteItem}
                          />
                        </div>
                      )}

                      <Link to="/optimizerdetails">
                        <button
                          // to="/optimizerdetails"
                          onClick={() => handleInputChange(optimizer, "/optimizer")}
                          className="px-2 py-2 border-2 border-purple-600 text-purple-600 rounded-md"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentcolor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              })
            );
          }

          const validGateways = selectedLocationData.gateways.filter(isGatewayValid);

          return validGateways.length === 0 ? (
            <tr>
              <td colSpan={4} className="text-center text-gray-700 dark:text-gray-400 px-4 py-3">
                No gateway found for the given location.
              </td>
            </tr>
          ) : (
            validGateways.map((gateway, rowIndex) => {
              const totalOptimizers = gateway.optimizers.filter((optimizer) => !isEmptyObject(optimizer)).length;
            
              return (
                <tr key={gateway._id} className="text-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700">
                  <td
                    className="px-4 py-3 hover:underline text-sm text-blue-500 font-semibold"
                    onClick={() => handleSelect("gateway", gateway._id)}
                  >
                    {gateway.gatewayId}
                  </td>
                  <td className="px-4 py-3 text-sm">{totalOptimizers}</td>
                  <td className="px-4 py-3 text-sm">
                    <div className="toggle_btn">
                      <input
                        type="checkbox"
                        id={`toggle-btn-1-${rowIndex}`}
                        defaultChecked={
                          checkboxStates1[gateway._id] || false
                        }
                        onClick={() => readyToConfig(gateway.gatewayId)}
                      />
                      <label htmlFor={`toggle-btn-1-${rowIndex}`} />
                    </div>
                  </td>
                  <td className="flex space-x-2 px-4 py-3">
                    <button
                      className="px-2 py-2 border-2 border-red-600 text-purple-600 rounded-md"
                      onClick={() => openDeleteModal(gateway, "gateway")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="red"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                      </svg>
                    </button>

                    {isDeleteModelOpen && (
                      <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
                        <DeleteModal
                          closeModal={() => closeModal()}
                          Data={selectedDeleteItem}
                        />
                      </div>
                    )}
                    <Link to="/gatewaydetails">
                      <button
                        // to="/gatewaydetails"
                        onClick={() => handleInputChange(gateway, "/gateway")}
                        className="px-2 py-2 border-2 border-purple-600 text-purple-600 rounded-md"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentcolor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                          <circle cx="12" cy="12" r="3"></circle>
                        </svg>
                      </button>
                    </Link>
                  </td>
                </tr>
              );
            })
          );
        }

        return selectedStateData.locations.map((location) => {
          let totalGateways = 0;
          let totalOptimizers = 0;

          const validGateways = location.gateways.filter(isGatewayValid);
          totalGateways += validGateways.length;
        
          validGateways.forEach((gateway) => {
            const validOptimizers = gateway.optimizers.filter((optimizer) => !isEmptyObject(optimizer));
            totalOptimizers += validOptimizers.length;
          });
          return (
            <tr key={location.locId} className="text-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700">
              <td className="px-4 py-3 hover:underline text-sm text-blue-500 font-semibold cursor-pointer" onClick={() => handleSelect("location", location.locId)}>{location.locationName}</td>
              <td className="px-4 py-3 text-sm">{totalGateways}/{totalOptimizers}</td>
              <td className="flex space-x-2 px-4 py-3">
                <button
                  className="px-2 py-2 border-2 border-red-600 text-purple-600 rounded-md"
                  onClick={() => openDeleteModal(location, "location")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="red"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </button>

                {isDeleteModelOpen && (
                  <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
                    <DeleteModal
                      closeModal={() => closeModal()}
                      Data={selectedDeleteItem}
                    />
                  </div>
                )}

                <button
                  onClick={() => handleSelect("location", location.locId)}
                  className="px-2 py-2 border-2 border-purple-600 text-purple-600 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                    <circle cx="12" cy="12" r="3"></circle>
                  </svg>
                </button>
              </td>
            </tr>
          );
        });
      }

      return selectedEnterpriseData.states.map((state) =>{
        let totalLocations = 0;
        let totalGateways = 0;
        let totalOptimizers = 0;

        state.locations.forEach((location) => {
          totalLocations++;
          const validGateways = location.gateways.filter(isGatewayValid);
          totalGateways += validGateways.length;

          validGateways.forEach((gateway) => {
            const validOptimizers = gateway.optimizers.filter((optimizer) => !isEmptyObject(optimizer));
            totalOptimizers += validOptimizers.length;
          });
        });
      
        return(
          <tr key={state.stateId} className="text-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700">
            <td className="px-4 py-3 hover:underline text-sm text-blue-500 font-semibold" onClick={() => handleSelect("state", state.stateId)}>{state.stateName}</td>
            <td className="px-4 py-3 text-sm">{totalLocations}</td>
            <td className="px-4 py-3 text-sm">{totalGateways}/{totalOptimizers}</td>
            <td className="flex space-x-2 px-4 py-3">
              <button
                  className="px-2 py-2 border-2 border-red-600 text-purple-600 rounded-md"
                  onClick={() => openDeleteModal(state, "state")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="red"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </button>

                {isDeleteModelOpen && (
                  <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
                    <DeleteModal
                      closeModal={() => closeModal()}
                      Data={selectedDeleteItem}
                    />
                  </div>
                )}
              <button
                onClick={() => handleSelect("state", state.stateId)}
                className="px-2 py-2 border-2 border-purple-600 text-purple-600 rounded-md"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentcolor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              </button>
            </td>
          </tr>
        );
      });
    }

    return enterpriseTableData.map((item) => {
      let totalLocations = 0;
      let totalGateways = 0;
      let totalOptimizers = 0;

      item.states.forEach((state) => {
        totalLocations += state.locations.length;
  
        state.locations.forEach((location) => {
          const validGateways = location.gateways.filter(isGatewayValid);
          totalGateways += validGateways.length;
  
          validGateways.forEach((gateway) => {
            const validOptimizers = gateway.optimizers.filter((optimizer) => !isEmptyObject(optimizer));
            totalOptimizers += validOptimizers.length;
          });
        });
      });
      
      return (
        <tr key={item.entepriseId} className="text-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700">
          <td className="px-4 py-3 hover:underline text-sm text-blue-500 font-semibold" onClick={() => handleSelect("enterprise", item.entepriseId)}>{item.enterpriseName}</td>
          <td className="px-4 py-3 text-sm">{totalLocations}</td>
          <td className="px-4 py-3 text-sm">{totalGateways}/{totalOptimizers}</td>
          <td className="flex space-x-2 px-4 py-3">
            <button
              onClick={() => openEditModal(item)}
              className="px-2 py-2 border-2 border-purple-600 text-purple-600 rounded-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentcolor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polygon points="16 3 21 8 8 21 3 21 3 16 16 3"></polygon>
              </svg>
            </button>

            {isEditModelOpen && (
              <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
                <EnterpriseEditModal
                  closeModal={() => closeModal()}
                  Data={selectedItem}
                />
              </div>
            )}

            <button
              className="px-2 py-2 border-2 border-red-600 text-purple-600 rounded-md"
              onClick={() => openDeleteModal(item, "enterprise")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="red"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </svg>
            </button>

            {isDeleteModelOpen && (
              <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
                <DeleteModal
                  closeModal={() => closeModal()}
                  Data={selectedDeleteItem}
                />
              </div>
            )}

            <button
              onClick={() => handleSelect("enterprise", item.entepriseId)}
              className="px-2 py-2 border-2 border-purple-600 text-purple-600 rounded-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentcolor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
            </button>
          </td>
        </tr>
      );
    });   
  };

    // Ready to Config

    const [checkboxStates1, setCheckboxStates1] = useState({});

    async function readyToConfig(Id) {
      setCheckboxStates1((prevStates) => ({
        ...prevStates,
        [Id]: !prevStates[Id], // Toggle the state for the specific item
      }));
  
      if (!checkboxStates1[Id] === true) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API}/api/hardware/device/ready/to/config/${Id}`,
            // Request body
            {},
            {
              // Headers
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
            }
          );
        } catch (error) {}
      }
    }
 
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const paginationRange = 1;
  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(enterpriseTableData.length / itemsPerPage);

    // Ensure the new page is within valid range
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const renderPaginationButtons = () => {
    const totalPages = Math.ceil(enterpriseTableData.length / itemsPerPage); // Set the total number of pages

    // Display all buttons if there are less than or equal to 6 pages
    if (totalPages <= 6) {
      return Array.from({ length: totalPages }, (_, i) => i + 1).map((i) => (
        <li key={i}>
          <button
            className={`px-3 py-1 rounded-md ${
              currentPage === i
                ? "text-white bg-purple-600 border border-r-0 border-purple-600"
                : "focus:outline-none focus:shadow-outline-purple"
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        </li>
      ));
    }

    const pages = [];
    const startPage = Math.max(1, currentPage - paginationRange);
    const endPage = Math.min(totalPages, startPage + 2 * paginationRange);
    if (startPage > 1) {
      pages.push(
        <li key={1}>
          <button
            className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple"
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
        </li>
      );

      if (startPage > 2) {
        pages.push(<span key="startEllipsis">...</span>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i}>
          <button
            className={`px-3 py-1 rounded-md ${
              currentPage === i
                ? "text-white bg-purple-600 border border-r-0 border-purple-600"
                : "focus:outline-none focus:shadow-outline-purple"
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(<span key="endEllipsis">...</span>);
      }

      pages.push(
        <li key={totalPages}>
          <button
            className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple"
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    return pages;
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = enterpriseTableData.slice(startIndex, endIndex);

  useEffect(() => {
    if (trigger) {
      const handleResponse = (response, clearAction) => {
        if (response) {
          showToast(response.message, "success");
          dispatch(setEnterprisesChanges(true));
          dispatch(clearAction());
        }
      };
  
      handleResponse(add_enterprise_response, clearAdd_enterprise_response);
      handleResponse(add_state_response, clearAdd_state_response);
      handleResponse(add_locationlist_response, clearAddLoctation_response);
      handleResponse(add_gatewaylist_response, clearGatewayResponse);
      handleResponse(add_optimizerlist_response, clearAddOptimizerResponse);
      handleResponse(edit_enterprise_response, clearEdit_enterprise_response);
      handleResponse(edit_gateway_response, clearEdit_gateway_response);
      handleResponse(edit_optimizer_response, clearEditOptimizerResponse);
  
      setTrigger(false);
    }
  
    dispatch(clearResponse());
    dispatch(clearError());
  
    if (allDelete_response) {
      setTrigger(true);
      showToast(allDelete_response.message, "success");
    }
    
    dispatch(clearDelete_response());
  }, [trigger, customer_response, dispatch, status]);
  
  // pop-up
  const showToast = (message, type) => {
    toast[type](message, {
      position: "bottom-left",
      autoClose: 3000,
    });
  };

  return (
    <>
      {loading && <Loader />}
      <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
        <LeftMenuList />
        <div className="flex flex-col flex-1 w-full">
          <TopNavbar />
          <main className="h-full overflow-y-auto">
            <div className="container px-6 mx-auto grid">
              <div className="flex justify-between">
                <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                  Enterprise
                </h2>
                <div className="px-6 my-6">
                <button
                    onClick={() => openModal(selectedIds.enterprise ? selectedIds.state ? selectedIds.location ? selectedIds.gateway ? 
                        "optimizer" : "gateway" : "location" : "state" : "enterprise")}
                    className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                >
                    {selectedIds.enterprise ? selectedIds.state ? selectedIds.location ? selectedIds.gateway ? 
                        "Add Optimizer" : "Add Gateway" : "Add Location" : "Add State" : "Add Enterprise"}
                    <span className="ml-2" aria-hidden="true">+</span>
                </button>
                  {isModalOpen && (
                    <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
                        {modalType === "enterprise" && <EnterpriseModal closeModal={() => closeModal()}/>}
                        {modalType === "state" && <StateModals closeModal={() => closeModal()} DataIds={{EnterpriseId:selectedIds.enterprise}}/>}
                        {modalType === "location" && <LocationModals closeModal={() => closeModal()} DataIds={{ EnterpriseId: selectedIds.enterprise, StateId: selectedIds.state }}/>}
                        {modalType === "gateway" && <GatewayModals closeModal={() => closeModal()}  data1={{ Enterprise, EnterpriseUser, State, Location }} DataIds={{ EnterpriseId: selectedIds.enterprise, LocationId: selectedIds.location }}/>}
                        {modalType === "optimizer" && <OptimizerModel closeModal={() => closeModal()} Data={{GatewayId : gatewayId}}/>}
                    </div>
                  )}
                </div>
              </div>

              {/* Table */}
              <div className="w-full overflow-x-auto">
                {/* Breadcrumb */}
                {renderBreadcrumbs()}

                <table className="w-full whitespace-no-wrap">
                  <thead>
                    <tr className="text-xs font-semibold tracking-wide text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:bg-gray-800">
                      <th className="px-4 py-3">{!selectedIds.enterprise ? "Enterprise Name" : selectedIds.state ? selectedIds.location ? selectedIds.gateway ? "Optimizer ID" : "Gateway ID" : "Location Name" : "State Name"}</th>
                      {!selectedIds.state && (
                        <th className="px-4 py-3">No of Location</th>
                      )}
                      <th className="px-4 py-3">
                        {!selectedIds.gateway ? (!selectedIds.location ? "No of Gateways/Optimizers" : "No of Optimizers") : "Optimizer Name"}
                      </th>
                      {selectedIds.location && !selectedIds.gateway && (
                        <th className="px-4 py-3">Ready To Config</th>
                      )}
                      {selectedIds.gateway && (
                        <th className="px-4 py-3">Status</th>
                      )}
                      <th className="px-4 py-3">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                    {renderEnterpriseOrStateRows()}
                  </tbody>
                </table>

                {/* Pagination */}
                <div className="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
                  <span className="flex items-center col-span-3">
                    {`Showing ${startIndex + 1}- ${
                      enterpriseTableData.length
                    } of ${endIndex} `}
                  </span>
                  <span className="col-span-2"></span>

                  <span className="flex col-span-4 mt-2 sm:mt-auto sm:justify-end">
                    <nav aria-label="Table navigation">
                      <ul className="inline-flex items-center">
                        <li>
                          <button
                            className="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple"
                            aria-label="Previous"
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                            <svg
                              aria-hidden="true"
                              className="w-4 h-4 fill-current"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clipRule="evenodd"
                                fillRule="evenodd"
                              ></path>
                            </svg>
                          </button>
                        </li>
                        {renderPaginationButtons()}
                        <li>
                          <button
                            className="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple"
                            aria-label="Next"
                            disabled={currentPage === 10}
                            onClick={() => handlePageChange(currentPage + 1)}
                          >
                            <svg
                              className="w-4 h-4 fill-current"
                              aria-hidden="true"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                                fillRule="evenodd"
                              ></path>
                            </svg>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </span>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        //  transition: Bounce
      />
    </>
  );
}

export default EnterprisesList;
