import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ByPassSchedule, TurnOnOffBypass, clearError,} from "../../Slices/Enterprise/ByPassSlice";
import IdleTimer from "../../IdleTimer/IdleTimer";
import { fetchEnterprises } from "../../Slices/Enterprise/NewEnterpriseDataSlice";
import moment from "moment";
import "daterangepicker";
import $ from "jquery";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ScheduleByPassModal({ closeModal, formDisabled, hideOptimizer,}) {
  const dispatch = useDispatch();
  const [scheduleData, setScheduleData] = useState({
    GatewayID: "",
    OptimizerId: "",
    ByPassType: "",
    Status: "",
    dateRange: {
      startTime: "",
      endTime: "",
    },
    deviceStatus: null,
  });
  const [errorMessage, setErrorMessage] = useState([]);
  const [sessionTimeout, setSessionTimeout] = useState(null);
  const [selectedEnterprise, setSelectedEnterprise] = useState("");
  const [selectedCountryState, setSelectedCountryState] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedGateway, setSelectedGateway] = useState("");
  const [selectedOptimizer, setSelectedOptimizer] = useState("");
  const dateRangePickerRef = useRef(null);

  const header = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
  };

  const { enterprises, error } = useSelector((state) => state.enterpriseDataSlice);

  useEffect(() => {
    dispatch(fetchEnterprises({ header }));
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setScheduleData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const initializeDateRangePicker = () => {
      if (dateRangePickerRef.current) {
        $(dateRangePickerRef.current).daterangepicker({
          timePicker: true,
          startDate: moment().startOf('hour'),
          endDate: moment().startOf('hour').add(32, 'hour'),
          locale: {
            format: 'M/DD/YYYY hh:mm A',
          },
          opens: 'top', 
          parentEl: "body", 
          drops: 'up' 
        }).on('apply.daterangepicker', (ev, picker) => {
          setScheduleData((prevData) => ({
            ...prevData,
            dateRange: {
              startTime: picker.startDate.format('M/DD/YYYY hh:mm:ss A'),
              endTime: picker.endDate.format('M/DD/YYYY hh:mm:ss A'),
            },
          }));
        });
      }
    };
  
    initializeDateRangePicker();
  
    return () => {
      if ($(dateRangePickerRef.current).data('daterangepicker')) {
        $(dateRangePickerRef.current).data('daterangepicker').remove();
      }
    };
  }, []);

  const handleAddClick = async() => {
    const errors = [];

    if (!selectedGateway) {
      errors.push("Please select a Gateway before proceeding.");
    }else if (!scheduleData.ByPassType) {
      errors.push("ByPass Type is required.");
    }

    if (scheduleData.ByPassType === "schedule") {
      if (!scheduleData.dateRange.startTime) {
        errors.push("Start Time is required for schedule.");
      }
      if (!scheduleData.dateRange.endTime) {
        errors.push("End Time is required for schedule.");
      }
    } else if (scheduleData.ByPassType === "instant") {
      if (!scheduleData.Status) {
        errors.push("Status is required for instant.");
      }
    }

     // Show error messages if any
     if (errors.length > 0) {
      setErrorMessage(errors);
      setTimeout(() => setErrorMessage([]), 3000);
      return;
    }

    let data;
    const { startTime, endTime } = scheduleData.dateRange;

    if (scheduleData.ByPassType === "schedule") {
      data = {
        GatewayID: selectedGateway,         
        OptimizerId: selectedOptimizer || null,      
        startTime: Math.floor(new Date(startTime).getTime() / 1000),  
        endTime: Math.floor(new Date(endTime).getTime() / 1000),
      }
      dispatch(ByPassSchedule({ data, header })).then((response) => {
        handleResponse(response);
      });
    }else if (scheduleData.ByPassType === "instant") {
      data = {
        GatewayID: selectedGateway,         
        OptimizerId: selectedOptimizer || null,
        Status: scheduleData.Status,        
      }
      dispatch(TurnOnOffBypass({ data, header })).then((response) => {
        handleResponse(response);
      });
    }
  };

  const handleResponse = (response) => {
    const message = response.payload.message;
    if (!response.payload.success) {
      setErrorMessage(Array.isArray(message) ? message : [message]);
    } else {
      const successMessage = Array.isArray(message) ? message.join(", ") : message;
      console.log(successMessage);

      // showToast(successMessage, "success");

      closeModal();
    }
  };

  const showToast = (message, type) => {
    toast[type](message, {
      position: "bottom-left",
      autoClose: 2000,
    });
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
      setTimeout(() => setErrorMessage([]), 3000);
      dispatch(clearError());
    }
  }, [error, dispatch]);

  useEffect(() => {
    const timeout = 300000;
    const session = <IdleTimer data={timeout} />;
    setSessionTimeout(session);
  }, []);

  // Handle dropdown changes
  const handleEnterpriseChange = (event) => {
    setSelectedEnterprise(event.target.value);
    setSelectedCountryState("");
    setSelectedLocation(""); // Reset state and location and gateway when country changes
    setSelectedGateway("");
    setSelectedOptimizer("");
  };

  const handleCountryStateChange = (event) => {
    setSelectedCountryState(event.target.value);
    setSelectedLocation("");
    setSelectedGateway(""); // Reset gateway and location when state changes
    setSelectedOptimizer("");
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
    setSelectedGateway(""); // Reset gateway when state changes
    setSelectedOptimizer("");
  };

  const handleGatewayChange = (event) => {
    setSelectedGateway(event.target.value);
    setSelectedOptimizer("");
  };

  const handleOptimizerChange = (event) => {  
    setSelectedOptimizer(event.target.value);
  };

  // Get states and cities based on the selected enterprise and state
  const selectedEnterpriseData = enterprises.find((enterprise) => enterprise.entepriseId === selectedEnterprise);

  const countrystates = selectedEnterpriseData ? selectedEnterpriseData.states : [];

  const selectedCountryStateData = countrystates.find((countrystate) => countrystate.stateId === selectedCountryState);

  const locations = selectedCountryStateData ? selectedCountryStateData.locations : [];

  const selectedLocationData = locations.find((location) => location.locId === selectedLocation);

  const gateways = selectedLocationData ? selectedLocationData.gateways : [];

  const selectedGatewayData = gateways.find((gateway) => gateway._id === selectedGateway);
  
  const optimizers = selectedGatewayData ? selectedGatewayData.optimizers : [];
  
  // Disable Status when "schedule" is selected
  const isStatusDisabled = scheduleData.ByPassType === "schedule";

  // Disable Start Time and End Time when "instant" is selected
  const isTimeDisabled = scheduleData.ByPassType === "instant";

  return (
    <div className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
      {sessionTimeout}
      <div
        className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
        role="dialog"
        onClick={(e) => e.stopPropagation()}
      >
        <header className="flex justify-end">
          <button
            className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover:text-gray-700"
            aria-label="close"
            onClick={closeModal}
          >
            <svg
              className="w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              role="img"
              aria-hidden="true"
            >
              <path
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
                fillRule="evenodd"
              ></path>
            </svg>
          </button>
        </header>

        <div className="mb-6">
          <form action="">
            {/* Enterprise Dropdown */}
            <label className="block mt-4 text-sm w-full">
              <span className="text-gray-700 dark:text-gray-400">
                Enterprise <span className="text-red-600">*</span>
              </span>
              <select
                className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                name="customer"
                onChange={handleEnterpriseChange}
                value={selectedEnterprise}
                disabled={formDisabled}
              >
                <option value="">Select Enterprise</option>
                {enterprises.map((enterprise) => (
                  <option
                    key={enterprise.enterpriseName}
                    value={enterprise.entepriseId}
                  >
                    {enterprise.enterpriseName}
                  </option>
                ))}
              </select>
            </label>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                {/* State Dropdown */}
                <div style={{ flex: 1, marginRight: '1rem' }}>
                    <label className="block mt-3 text-sm w-full">
                        <span className="text-gray-700 dark:text-gray-400">
                          State <span className="text-red-600">*</span>
                        </span>
                        <select
                            className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                            name="state"
                            onChange={handleCountryStateChange}
                            value={selectedCountryState}
                            disabled={formDisabled || !selectedEnterprise}
                        >
                            <option value="">Select State</option>
                            {countrystates.map((countrystate) => (
                            <option
                                key={countrystate.stateName}
                                value={countrystate.stateId}
                            >
                                {countrystate.stateName}
                            </option>
                            ))}
                        </select>
                    </label>
                </div>

                {/* Location Dropdown */}
                <div style={{ flex: 1, marginRight: '1rem' }}>
                    <label className="block mt-3 text-sm w-full">
                        <span className="text-gray-700 dark:text-gray-400">
                          Location <span className="text-red-600">*</span>
                        </span>
                        <select
                            className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                            name="location"
                            onChange={handleLocationChange}
                            value={selectedLocation}
                            disabled={formDisabled || !selectedCountryState}
                        >
                            <option value="">Select Location</option>
                            {locations.map((location) => (
                            <option key={location.locId} value={location.locId}>
                                {location.locationName}
                            </option>
                            ))}
                        </select>
                    </label>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                {/* Gateway Dropdown */}
                <div style={{ flex: 1, marginRight: '1rem' }}>
                    <label className="block mt-3 text-sm w-full">
                        <span className="text-gray-700 dark:text-gray-400">
                          Gateway <span className="text-red-600">*</span>
                        </span>
                        <select
                          className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                          name="gatewayId"
                          onChange={handleGatewayChange}
                          value={selectedGateway}
                          disabled={formDisabled || !selectedLocation}
                        >
                          <option value="">Select Gateway</option>
                          {gateways.map((gateway) => (
                            <option key={gateway.gatewayId} value={gateway._id}>
                              {gateway.gatewayId}
                            </option>
                          ))}
                        </select>
                    </label>
                </div>

                {/* Optimizer Dropdown */}
                <div style={{ flex: 1, marginRight: '1rem' }}>
                    <label className="block mt-3 text-sm w-full">
                        <span className="text-gray-700 dark:text-gray-400">
                            Optimizer
                        </span>
                        <select
                            className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                            name="optimizerId"
                            onChange={handleOptimizerChange}
                            value={selectedOptimizer}
                            disabled={formDisabled || !selectedGateway}
                        >
                            <option value="">Select Optimizer</option>
                            {optimizers.map((optimizer) => (
                            <option key={optimizer.id} value={optimizer.id}>
                                {optimizer.name}
                            </option>
                            ))}
                        </select>
                    </label>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                {/* ByPass Type */}
                <div style={{ flex: 1, marginRight: '1rem' }}>
                    <label className="block mt-3 text-sm w-full">
                        <span className="text-gray-700 dark:text-gray-400">ByPass Type</span>
                        <select
                          name="ByPassType"
                          onChange={handleInputChange}
                          value={scheduleData.ByPassType}
                          className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                        >
                          <option value="">Select ByPass Type</option>
                          <option value="schedule">Schedule</option>
                          <option value="instant">Instant</option>
                        </select>
                    </label>
                </div>

                {/* Status */}
                <div style={{ flex: 1, marginRight: '1rem' }}>
                    <label className="block mt-3 text-sm w-full">
                        <span className="text-gray-700 dark:text-gray-400">Status</span>
                        <select
                          name="Status"
                          onChange={handleInputChange}
                          value={scheduleData.Status}
                          disabled={isStatusDisabled}
                          className= {`block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray ${isStatusDisabled ? 'cursor-not-allowed' : ''}`}
                        >
                          <option value="">Select Status</option>
                          <option value="on">On</option>
                          <option value="off">Off</option>
                        </select>
                    </label>
                </div>
            </div>
            
            {/* Time Inputs */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {/* StartTime */}
                {/* <div style={{ flex: 1, marginRight: '1rem' }}>
                    <label className="block mt-3 text-sm w-full">
                        <span className="text-gray-700 dark:text-gray-400">Start Time</span>
                        <input
                            type="datetime-local"
                            name="startTime"
                            value={scheduleData.startTime}
                            onChange={handleInputChange}
                            disabled={isTimeDisabled}
                            className={`block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-input focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray ${isTimeDisabled ? 'cursor-not-allowed' : ''}`}/>
                    </label>
                </div> */}

                {/* End Time */}
                {/* <div style={{ flex: 1, marginRight: '1rem' }}>
                    <label className="block mt-3 text-sm w-full">
                        <span className="text-gray-700 dark:text-gray-400">End Time</span>
                        <input
                            type="datetime-local"
                            name="endTime"
                            value={scheduleData.endTime}
                            onChange={handleInputChange}
                            disabled={isTimeDisabled}
                            className={`block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-input focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray ${isTimeDisabled ? 'cursor-not-allowed' : ''}`}
                        />
                    </label>
                </div> */}
                {/* <div> */}
                  <label className="block mt-3 text-sm w-full">
                    <span className="text-gray-700 dark:text-gray-400">Date Range</span>
                      <input ref={dateRangePickerRef}
                        name="datetimes"
                        disabled={isTimeDisabled}
                        className={`block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-input focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray ${isTimeDisabled ? 'cursor-not-allowed' : ''}`}
                      />
                    </label>
                  {/* </div> */}
            </div>
            
          </form>
        </div>

        {Array.isArray(errorMessage) && errorMessage.length > 0 && (
          <div className="mt-2">
            {errorMessage.map((msg, index) => (
              <p className="text-red-600 text-center text-sm" key={index}>{msg}</p>
            ))}
          </div>
        )}

        <button
          type="submit"
          onClick={handleAddClick}
          className="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
        >
          Add
        </button>
      </div>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          //  transition: Bounce
        />
    </div>
  );
}

export default ScheduleByPassModal;
