import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {CONFIGS, ENERGYMETER, AMBIENTINFO, ACSTATUS, BYPASSDATA, DELOITTELINKS} from '../api/api';

export const Configs = createAsyncThunk(
    "Configs",
    async ({ data, header }, { rejectWithValue }) => {
      try {
        const response = await CONFIGS(data, header);
        return response.data;
      } catch (error) {
        if (
          error.response.data.message === "Invalid token" ||
          error.response.data.message === "Access denied"
        ) {
          window.localStorage.clear();
          window.location.href = "./";
        }
        return rejectWithValue(error.response.data);
      }
    }
);

export const EnergyMeter = createAsyncThunk(
    "EnergyMeter",
    async ({ data, header }, { rejectWithValue }) => {
      try {
        const response = await ENERGYMETER(data, header);
        return response.data;
      } catch (error) {
        if (
          error.response.data.message === "Invalid token" ||
          error.response.data.message === "Access denied"
        ) {
          window.localStorage.clear();
          window.location.href = "./";
        }
        return rejectWithValue(error.response.data);
      }
    }
);

export const AmbientInfo = createAsyncThunk(
    "AmbientInfo",
    async ({ data, header }, { rejectWithValue }) => {
      try {
        const response = await AMBIENTINFO(data, header);
        return response.data;
      } catch (error) {
        if (
          error.response.data.message === "Invalid token" ||
          error.response.data.message === "Access denied"
        ) {
          window.localStorage.clear();
          window.location.href = "./";
        }
        return rejectWithValue(error.response.data);
      }
    }
);

export const BypassData = createAsyncThunk(
    "BypassData",
    async ({ data, header }, { rejectWithValue }) => {
      try {
        const response = await BYPASSDATA(data, header);
        return response.data;
      } catch (error) {
        if (
          error.response.data.message === "Invalid token" ||
          error.response.data.message === "Access denied"
        ) {
          window.localStorage.clear();
          window.location.href = "./";
        }
        return rejectWithValue(error.response.data);
      }
    }
);

export const AcStatus = createAsyncThunk(
    "AcStatus",
    async ({ data, header }, { rejectWithValue }) => {
      try {
        const response = await ACSTATUS(data, header);
        return response.data;
      } catch (error) {
        if (
          error.response.data.message === "Invalid token" ||
          error.response.data.message === "Access denied"
        ) {
          window.localStorage.clear();
          window.location.href = "./";
        }
        return rejectWithValue(error.response.data);
      }
    }
);

export const DeloitteLinks = createAsyncThunk(
  "DeloitteLinks",
  async ({ data, header }, { rejectWithValue }) => {
    try {
      const response = await DELOITTELINKS(data, header);
      return response.data;
    } catch (error) {
      if (
        error.response.data.message === "Invalid token" ||
        error.response.data.message === "Access denied"
      ) {
        window.localStorage.clear();
        window.location.href = "./";
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const DeloitteDataSlice = createSlice({
    name: "deloitteDataSlice",
    initialState: {
        status: "",
        loading: false,
        configs: null,
        energyMeter: null,
        ambientInfo: null,
        bypassData: null,
        acStatus: null,
        deloitteLinks: null,
        error: null,
    },
    reducers: {
      clear_response: (state) => {
        state.configs = null;
        state.energyMeter = null;
        state.ambientInfo = null;
        state.bypassData = null;
        state.acStatus = null;
        state.deloitteLinks = null;
      },
      clear_error: (state) => {
        state.error = null;
      },
    },
    extraReducers: (builder) => {
        builder
            //Config APi
            .addCase(Configs.pending, (state) => {
              state.status = "Loading...";
              state.loading = true;
            })
            .addCase(Configs.fulfilled, (state, { payload }) => {
              state.status = "Success";
              state.loading = false;
              state.configs = payload;
              state.error = null;
            })
            .addCase(Configs.rejected, (state, { payload }) => {
              state.status = "Failed";
              state.loading = false;
              state.error = payload;
            })
          
            //enerymeter api
            .addCase(EnergyMeter.pending, (state) => {
              state.status = "Loading...";
              state.loading = true;
            })
            .addCase(EnergyMeter.fulfilled, (state, { payload }) => {
              state.status = "Success";
              state.energyMeter = payload;
              state.loading = false;
              state.error = null;
            })
            .addCase(EnergyMeter.rejected, (state, { payload }) => {
              state.status = "Failed";
              state.loading = false;
              state.error = payload;
            })
          
            // ambientinfo Api
            .addCase(AmbientInfo.pending, (state) => {
              state.status = "Loading...";
              state.loading = true;
            })
            .addCase(AmbientInfo.fulfilled, (state, { payload }) => {
              state.status = "Success";
              state.ambientInfo = payload;
              state.loading = false;
              state.error = null;
            })
            .addCase(AmbientInfo.rejected, (state, { payload }) => {
              state.status = "Failed";
              state.loading = false;
              state.error = payload;
            })
          
            //ByPassData Api
            .addCase(BypassData.pending, (state) => {
              state.status = "Loading...";
              state.loading = true;
            })
            .addCase(BypassData.fulfilled, (state, { payload }) => {
              state.status = "Success";
              state.bypassData = payload;
              state.loading = false;
              state.error = null;
            })
            .addCase(BypassData.rejected, (state, { payload }) => {
              state.status = "Failed";
              state.loading = false;
              state.error = payload;
            })
          
            //AcStatus Api
            .addCase(AcStatus.pending, (state) => {
              state.status = "Loading...";
              state.loading = true;
            })
            .addCase(AcStatus.fulfilled, (state, { payload }) => {
              state.status = "Success";
              state.acStatus = payload;
              state.loading = false;
              state.error = null;
            })
            .addCase(AcStatus.rejected, (state, { payload }) => {
              state.status = "Failed";
              state.loading = false;
              state.error = payload;
            })

            //Deloitte Links Api
            .addCase(DeloitteLinks.pending, (state) => {
              state.status = "Loading...";
              state.loading = true;
            })
            .addCase(DeloitteLinks.fulfilled, (state, { payload }) => {
              state.status = "Success";
              state.deloitteLinks = payload;
              state.loading = false;
              state.error = null;
            })
            .addCase(DeloitteLinks.rejected, (state, { payload }) => {
              state.status = "Failed";
              state.loading = false;
              state.error = payload;
            })
      },
})

export const { clear_response, clear_error } = DeloitteDataSlice.actions;
export default DeloitteDataSlice.reducer;