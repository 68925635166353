import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Delete,
  clearDelete_response,
  clearDelete_error,
} from "../../Slices/Enterprise/enterpriseSlice";
import IdleTimer from "../../IdleTimer/IdleTimer";
import Loader from "../../utils/Loader";

function DeleteModals({ closeModal, Data }) {
  const header = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
  };
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [sessionTimeout, setSessionTimeout] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { allDelete_response, allDelete_error, loading } = useSelector(
    (state) => state.enterpriseSlice
  );
  const dispatch = useDispatch();

  const handleDeleteConfirmation = () => {
    setConfirmDelete(true);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  async function allDelete() {
    const { email, password } = formData;
    if (!email || !password) {
      setError("Email and Password are required");
      setTimeout(() => {
        setError("");
      }, 5000);
      return;
    }
    const deleteData = {
      ...Data,
      email,
      password,
    };

    dispatch(Delete({ deleteData, header }));
    // closeModal();
  }
  useEffect(() => {
    // If deletion is successful, close modal
    if (allDelete_response) {
      closeModal();
      dispatch(clearDelete_response());
    }

    // If there is an error, display it and keep modal open
    if (allDelete_error) {
      const error = allDelete_error.response.data;
      setError(error.message || "An unknown error occurred");
      dispatch(clearDelete_error());
    }
  }, [allDelete_response, allDelete_error, dispatch, closeModal]);
  
  useEffect(() => {
    const data = 300000;
    const session = <IdleTimer data={data} />;
    setSessionTimeout(session);
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div
        // onClick={closeModal}
        // onKeyDown={closeModal}
        className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
      >
        {" "}
        {sessionTimeout}
        <div
          onClick={(e) => e.stopPropagation()}
          className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
          role="dialog"
          id="modal"
        >
          <header className="flex justify-end">
            <button
              onClick={closeModal}
              className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover:hover:text-gray-700"
              aria-label="close"
            >
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                role="img"
                aria-hidden="true"
              >
                <path
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                  fillRule="evenodd"
                ></path>
              </svg>
            </button>
          </header>
          {!confirmDelete ? (
            <>
              <div className="my-4">
                <span className="text-gray-700 dark:text-gray-400 font-bold">
                  Are you sure you want to delete this {Data.group}?
                </span>
              </div>
              <button
                onClick={handleDeleteConfirmation}
                className="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
              >
                Yes
              </button>
              <button
                onClick={closeModal}
                className="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
              >
                No
              </button>
            </>
          ) : (
            <>
              <div className="">
                <span className="text-gray-700 dark:text-gray-400 font-bold">
                  Please enter your credentials to confirm deletion
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flex: 1, marginRight: "1rem" }}>
                  <label className="mt-4 mb-2 block text-sm w-full">
                    <span className="text-gray-700 dark:text-gray-400">
                      Email <span className="text-red-600">*</span>
                    </span>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    />
                  </label>
                </div>
                <div style={{ flex: 1, marginRight: "1rem" }}>
                  <label className="mt-4 mb-2 block text-sm w-full">
                    <span className="text-gray-700 dark:text-gray-400">
                      Password <span className="text-red-600">*</span>
                    </span>
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    />
                  </label>
                </div>
              </div>
              {/* Error Message */}
              {error && <p className="text-red-600 text-sm mt-2">{error}</p>}
              <button
                onClick={allDelete}
                className="w-full px-4 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
              >
                Confirm Delete
              </button>
              <button
                onClick={closeModal}
                className="w-full px-4 py-3 text-sm font-medium leading-5 transition-colors duration-150 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 focus:outline-none focus:shadow-outline-purple"
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default DeleteModals;
