import React, { useState, useEffect } from "react";
import LeftMenuList from "../../Common/LeftMenuList";
import TopNavbar from "../../Common/TopNavbar";
import { useSelector, useDispatch } from "react-redux";
import { enterpriseList } from "../../Slices/Enterprise/enterpriseSlice";
import { stateList, clearResponse } from "../../Slices/Enterprise/StateSlices";
import { locationList, clearLocationResponse, } from "../../Slices/Enterprise/LocationSlice";
import { GatewayList, clearGatewaysResponse, } from "../../Slices/Enterprise/GatewaySlice";
import { OptimizerList, clearOptimizerResponse, } from "../../Slices/Enterprise/OptimizerSlice";
import "react-toastify/dist/ReactToastify.css";
import OptimizerSettings from "./optimizerSettings";
import GatewaySSID from "./gatewayssid";
import NewReports from "../Report/EnterpriseData";

function Settings() {
  const dispatch = useDispatch();
  const [ssidTrigger, setSsidTrigger] = useState(false);
  const [selectedEnterprise, setSelectedEnterprise] = useState("");
  const [selectedCountryState, setSelectedCountryState] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedGateway, setSelectedGateway] = useState("");
  const [selectedOptimizer, setSelectedOptimizer] = useState("");
  const [formDisabled, setFormDisabled] = useState(false);
  const [isOptimizerSettingsEnabled, setIsOptimizerSettingsEnabled] =
    useState(false);
  const [isGatewaySSIDEnabled, setIsGatewaySSIDEnabled] = useState(false);
  const [isOptimizerDisabled, setIsOptimizerDisabled] = useState(true);
  const [userType, setUserType] = useState("");
  const [group, setGroup] = useState("");
  const [id, setId] = useState("");

  const [gatewayData, setGatewayData] = useState(null);
  const [optimizerData, setOptimizerData] = useState(null);

  const handleRadioChange = (type) => {
    setUserType(type);

    if (type === "Gateway SSID") {
      setIsGatewaySSIDEnabled(true);
      setIsOptimizerSettingsEnabled(false);
      setIsOptimizerDisabled(true); // Disable optimizer field
      setSelectedOptimizer("");
      setSsidTrigger(true); // Set ssidTrigger to true when Gateway SSID is selected
    } else if (type === "Optimizer Settings") {
      setIsOptimizerSettingsEnabled(true);
      setIsOptimizerDisabled(false); // Enable optimizer field
      setIsGatewaySSIDEnabled(false);
      setSsidTrigger(false); // Reset ssidTrigger if Optimizer Settings is selected
    } else {
      setIsOptimizerSettingsEnabled(false);
      setIsOptimizerDisabled(true); // Disable optimizer field
      setIsGatewaySSIDEnabled(false);
      setSsidTrigger(false); // Reset ssidTrigger if neither is selected
    }
  };

  // Set group and id based on selections
  const updateGroupAndId = () => {
    if (selectedOptimizer) {
      setGroup("optimizer");
      setId(selectedOptimizer);
    } else if (selectedGateway) {
      setGroup("gateway");
      setId(selectedGateway);
    } else if (selectedLocation) {
      setGroup("location");
      setId(selectedLocation);
    } else if (selectedCountryState) {
      setGroup("state");
      setId(selectedCountryState);
    } else if (selectedEnterprise) {
      setGroup("enterprise");
      setId(selectedEnterprise);
    } else {
      setGroup("");
      setId("");
    }
  };

  useEffect(() => {
    updateGroupAndId();
  }, [
    selectedEnterprise,
    selectedCountryState,
    selectedLocation,
    selectedGateway,
    selectedOptimizer,
  ]);

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      <LeftMenuList />
      <div className="flex flex-col flex-1 w-full">
        <TopNavbar />

        <main className="h-full pb-16 overflow-y-auto">
          <div className="container grid px-6 mx-auto">
            <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
              Settings
            </h2>
            <div className="w-full mb-8 overflow-hidden rounded-lg shadow-xs relative">
              <form action="">
                <NewReports
                  selectedEnterprise={selectedEnterprise}
                  selectedCountryState={selectedCountryState}
                  selectedLocation={selectedLocation}
                  selectedGateway={selectedGateway}
                  selectedOptimizer={selectedOptimizer}
                  setSelectedEnterprise={setSelectedEnterprise}
                  setSelectedLocation={setSelectedLocation}
                  setSelectedCountryState={setSelectedCountryState}
                  setSelectedGateway={setSelectedGateway}
                  setSelectedOptimizer={setSelectedOptimizer}
                  formDisabled={formDisabled}
                  isOptimizerDisabled={isOptimizerDisabled}
                  setGatewayData={setGatewayData}
                  setOptimizerData={setOptimizerData}
                />

                <ul
                  className="relative flex flex-wrap p-1 list-none ml-3 rounded-xl bg-blue-gray-50/60"
                  data-tabs="tabs"
                  role="list"
                >
                  <div className="inline-block bg-blue-100 dark:bg-purple-600 p-2 rounded-md shadow-md">
                    <label className="inline-flex items-center text-gray-600 dark:text-gray-400">
                      <input
                        type="radio"
                        className="text-purple-600 form-radio focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                        name="userType"
                        value="Gateway SSID"
                        onChange={() => handleRadioChange("Gateway SSID")}
                      />
                      <span className="ml-2 text-blue-500">Gateway SSID</span>
                    </label>
                  </div>

                  <div className="inline-block bg-blue-100 dark:bg-purple-600 p-2 rounded-md shadow-md ml-2">
                    <label className="inline-flex items-center text-gray-600 dark:text-gray-400">
                      <input
                        type="radio"
                        className="text-purple-600 form-radio focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                        name="userType"
                        value="Optimizer Settings"
                        onChange={() => handleRadioChange("Optimizer Settings")}
                      />
                      <span className="ml-2 text-blue-500">
                        Optimizer Settings
                      </span>
                    </label>
                  </div>
                </ul>

                {userType === "Gateway SSID" && ssidTrigger && (
                  <GatewaySSID
                    Data={{
                      ...gatewayData,
                      EnterpriseUserID: selectedEnterprise,
                    }}
                    LoctionID={selectedLocation} // Passing the location as a prop
                  />
                )}

                {userType === "Optimizer Settings" &&
                  isOptimizerSettingsEnabled &&
                  selectedOptimizer && (
                    <OptimizerSettings
                      Group={"optimizer"}
                      Id={selectedOptimizer}
                      OptimizerId={optimizerData.nameId}
                    />
                  )}
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Settings;
