import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import ByPassDeleteModal from "../../Modals/DeleteModals/ByPassDeleteModal";
import { TurnOnOffBypass } from "../../Slices/Enterprise/ByPassSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "../../Common/Pagination";

const ByPassList = ({ bypassList, onUpdatingList }) => {
  const dispatch = useDispatch();
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);
  const [toggleStates, setToggleStates] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const header = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
  };

  const role = `${window.localStorage.getItem("role")}`;

  const openDeleteModal = (row) => {
    setSelectedDeleteItem(row);
    setIsDeleteModelOpen(true);
  };

  const convertUnixToFormattedDate = (unixTimestamp) => {
    return moment.unix(unixTimestamp).format("DD/MM/YYYY HH:mm:ss");
  };

  const getStatus = (row) => {
    const currentTime = Date.now();
    const startTime = row.starttime * 1000;
    const endTime = row.endtime ? row.endtime * 1000 : null;

    if (
      row.bypasstype === "schedule" &&
      startTime > currentTime &&
      row.status === "active"
    ) {
      return "Scheduled";
    } else if (
      startTime <= currentTime &&
      row.status === "active" &&
      row.devicestatus === null
    ) {
      return "InProgress - Pending Ack";
    } else if (
      startTime <= currentTime &&
      row.status === "active" &&
      row.devicestatus === "active"
    ) {
      return "InProgress";
    } else if (
      row.status === "inactive" &&
      endTime <= currentTime &&
      row.devicestatus === "active"
    ) {
      return "Completed - Pending Ack";
    } else if (
      row.status === "inactive" &&
      endTime <= currentTime &&
      row.devicestatus === null
    ) {
      return "Completed - Device Not Bypassed";
    } else if (row.status === "inactive" && row.devicestatus === "inactive") {
      return "Completed";
    }
    return row.status;
  };

  const handleToggleChange = (index, row) => {
    if (row.status === "active" && row.devicestatus === "active") {
      const newStatus = "off";
      const optimizerId = row.optimizerId || undefined;

      const data = {
        OptimizerId: optimizerId !== "--" ? optimizerId : undefined,
        Status: newStatus,
        GatewayID: row.gatewayId,
      };

      // Dispatch the TurnOnOffBypass action
      dispatch(TurnOnOffBypass({ data: data, header })).catch((error) => {
        console.error("Failed to toggle bypass:", error);
      });
    } else {
      console.log(
        "Toggle off is not allowed because status or devicestatus is not active."
      );
    }
  };

  const closeModal = () => {
    setIsDeleteModelOpen(false);
    onUpdatingList();
  };

  // Pagination logic to slice the data for the current page

  const displayedData = bypassList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div data-tab-content="" className="px-2 py-4 overflow-x-auto">
      <div className="block opacity-100" id="matadeta" role="tabpanel">
        <table className="min-w-full divide-y divide-gray-200 table-auto">
          <thead>
            <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
              <th className="px-4 py-3">State</th>
              <th className="px-4 py-3">Location</th>
              <th className="px-4 py-3">Gateway ID</th>
              <th className="px-4 py-3">Optimizer Name</th>
              <th className="px-4 py-3">ByPass Type</th>
              <th className="px-4 py-3">ByPass Status</th>
              <th className="px-4 py-3">ByPass Acknowledged</th>
              <th className="px-4 py-3">StartTime</th>
              <th className="px-4 py-3">EndTime</th>
              <th className="px-4 py-3">Action</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
            {displayedData.length === 0 ? (
              <tr>
                <td colSpan="10" className="text-center py-4">
                  No data found
                </td>
              </tr>
            ) : (
              displayedData.map((row, index) => {
                return (
                  <tr key={index}>
                    <td className="px-4 py-3 text-sm">{row.state}</td>
                    <td className="px-4 py-3 text-sm">{row.location}</td>
                    <td className="px-4 py-3 text-sm">{row.gateway}</td>
                    <td className="px-4 py-3 text-sm">{row.optimizername}</td>
                    <td className="px-4 py-3 text-sm">{row.bypasstype}</td>
                    <td className="px-4 py-3 text-sm">{getStatus(row)}</td>
                    <td className="px-4 py-3 text-sm">
                      {row.byPassAcknowledged}/{row.optimizerCount}
                    </td>
                    <td className="px-4 py-3 text-sm">
                      {convertUnixToFormattedDate(row.starttime)}
                    </td>
                    <td className="px-4 py-3 text-sm">
                      {row.endtime
                        ? convertUnixToFormattedDate(row.endtime)
                        : "--"}
                    </td>
                    <td className="px-4 py-3 text-sm">
                      <div className="flex items-center justify-center space-x-2">
                        {row.bypasstype === "schedule" &&
                        row.starttime > Date.now() &&
                        row.devicestatus === null ? (
                          <button
                            className="px-2 py-2 border-2 border-red-600 text-purple-600 rounded-md"
                            onClick={() => openDeleteModal(row)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="red"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                          </button>
                        ) : null}

                        <div className="toggle_btn">
                          <input
                            type="checkbox"
                            id={`toggle-btn-${index}`}
                            checked={
                              row.status === "active" &&
                              row.devicestatus === "active"
                            }
                            onChange={() => handleToggleChange(index, row)}
                            className={`toggle_input ${
                              row.status !== "active" ||
                              row.devicestatus !== "active"
                                ? "cursor-not-allowed"
                                : ""
                            }`}
                            disabled={
                              row.status !== "active" ||
                              row.devicestatus !== "active"
                            }
                          />
                          <label
                            htmlFor={`toggle-btn-${index}`}
                            className="toggle_label"
                          >
                            <span className="toggle_span"></span>
                          </label>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>

        {/* Pagination */}

        <Pagination
          tableData={bypassList}
          itemsPerPage={itemsPerPage}
          onPageChange={(page) => setCurrentPage(page)}
          currentPage={currentPage}
        />
      </div>

      {/* ToastContainer */}
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ByPassList;
